
import { FaArrowsRotate } from "react-icons/fa6";
import { BsArrowsAngleExpand } from "react-icons/bs";
import { AiOutlineDrag } from "react-icons/ai";
import { PiSelectionLight } from "react-icons/pi";
import styled from "styled-components";
import { EditorContext } from "../../context/EditorContext";
import { useContext } from "react";

//

const TransformMenuContainer = styled.div`
    position: absolute;
    top: 150px;
    left: 10px;
    background-color: rgba( 0, 0, 0, 0.5 );
    backdrop-filter: blur( 10px );
    padding: 10px 10px 5px 10px;
    border-radius: 10px;
    z-index: 1000;
`;

interface ItemButtonProps {
    $active: boolean;
};

const ItemButton = styled.div<ItemButtonProps>`
    width: 30px;
    height: 30px;
    backdrop-filter: blur( 5px );
    border-radius: 5px;
    display: flex;
    justify-content: center;
    align-items: center;
    transition: background-color 0.3s;
    background-color: ${ props => props.$active ? 'rgba( 255, 0, 0, 0.5 )' : 'rgba( 255, 255, 255, 0.1 )' };
    &:hover {
        background-color: ${ props => props.$active ? 'rgba( 255, 0, 0, 0.5 )' : 'rgba( 255, 255, 255, 0.2 )' };
    }
    margin-bottom: 5px;
    color: ${ props => props.$active ? 'white' : 'rgba( 255, 255, 255, 0.7 )' };
    cursor: pointer;
`;

//

export const TransformMenu = () => {

    const EditorParams = useContext( EditorContext );

    //

    return (
        <TransformMenuContainer>
            <ItemButton $active={ EditorParams.selectMode === 'rect' } >
                <PiSelectionLight />
            </ItemButton>
            <ItemButton onClick={ EditorParams.setTransformMode.bind( this, 'translate' ) } $active={ EditorParams.transformMode === 'translate' } >
                <AiOutlineDrag style={{ fontSize: '20px' }} />
            </ItemButton>
            <ItemButton onClick={ EditorParams.setTransformMode.bind( this, 'rotate' ) } $active={ EditorParams.transformMode === 'rotate' } >
                <FaArrowsRotate />
            </ItemButton>
            <ItemButton onClick={ EditorParams.setTransformMode.bind( this, 'scale' ) } $active={ EditorParams.transformMode === 'scale' } >
                <BsArrowsAngleExpand />
            </ItemButton>
        </TransformMenuContainer>
    );

};
