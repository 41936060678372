
import { Canvas } from '@react-three/fiber';
import { useContext } from 'react';
import { SRGBColorSpace } from 'three';

import { WelcomeScene } from './WelcomeScene';
import { MapContext } from '../../context/MapContext';
import { WorldGfx } from './WorldGfx';
import { TransformMenu } from '../ModuleMenus/TransformMenu';
import { PaintBrushMenu } from '../ModuleMenus/PaintBrushMenu';
import { ObjectPlacingMenu } from '../ModuleMenus/ObjectPlacingMenu';

//

export const Renderport = () => {

    const { map } = useContext( MapContext );

    //

    return (
        <>
            { map ? (
                <>
                    <PaintBrushMenu />
                    <TransformMenu />
                    <ObjectPlacingMenu />
                </>
            ) : null }

            <Canvas id="main-viewport" gl={{ antialias: true, logarithmicDepthBuffer: true, outputColorSpace: SRGBColorSpace }} dpr={ 2 }>
                {
                    map ? <WorldGfx /> : <WelcomeScene />
                }
            </Canvas>
        </>
    );

};
