
import styled from "styled-components";

//

interface GeneralTabContentProps {
    $active: boolean;
};

export const TabWrapper = styled.div<GeneralTabContentProps>`
    position: absolute;
    top: 0px;
    left: 0px;
    width: 100%;
    height: 100%;
    overflow-y: auto;
    transition: opacity 0.3s;
    border-radius: 10px;
    z-index: 0;
    ${({ $active }) => !$active && `
        opacity: 0;
    `}
    ${({ $active }) => $active && `
        z-index: 100;
    `}
`;

export const TabTitle = styled.div`
    font-size: 15px;
    color: #fff;
    padding: 10px 15px;
    border-bottom: 1px solid rgba( 255, 255, 255, 0.2 );
    background-color: rgba( 50, 0, 0, 0.5 );
    font-weight: bold;
    text-transform: uppercase;
`;

export const BlockTitle = styled.div`
    width: 100%;
    margin: 10px 0px;
    padding: 10px;
    color: #fff;
    border-top: 1px solid #444;
    border-bottom: 1px solid #444;
`;

export const Block = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
`;
