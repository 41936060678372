
import { useContext } from "react";

import { TabTitle, TabWrapper, BlockTitle, Block } from "../TabContent";
import { MapContext } from "../../../context/MapContext";
import { EditorContext } from "../../../context/EditorContext";
import { Checkbox } from "@mui/material";
import { PropItem, PropItemInput, PropItemInputHalfWidth, PropItemInputOneThirdWidth, PropItemLabel } from "../RightPanel";

//

export const GeneralTabContent = ( { $active } : { $active: boolean }) => {

    const { map, setMap } = useContext( MapContext );
    const editorParams = useContext( EditorContext );

    const LightShadingChangeHandler = ( event: React.ChangeEvent<HTMLInputElement> ) => {

        editorParams.setLightShading( event.target.checked );

    };

    const NormalMapsChangeHandler = ( event: React.ChangeEvent<HTMLInputElement> ) => {

        editorParams.setNormalMaps( event.target.checked );

    };

    //

    if ( ! map ) return null;

    return (
        <TabWrapper $active={ $active } >
            <TabTitle>Map settings</TabTitle>
            <PropItem>
                <PropItemLabel>Map Name</PropItemLabel>
                <PropItemInput sourceValue={ map.name } setSourceValue={ ( value ) => { setMap( { ...map, name: value } ) }} />
            </PropItem>
            <PropItem>
                <PropItemLabel>Width</PropItemLabel>
                <PropItemInputHalfWidth sourceValue={ map.params.width } setSourceValue={ ( value ) => { setMap( { ...map, params: { ...map.params, width: parseInt( value ) } } ) }} />
                <PropItemInputHalfWidth sourceValue={ map.params.height } setSourceValue={ ( value ) => { setMap( { ...map, params: { ...map.params, height: parseInt( value ) } } ) }} />
            </PropItem>
            <PropItem>
                <PropItemLabel>Stick objects to terrain</PropItemLabel>
                <Checkbox />
            </PropItem>
            <PropItem>
                <PropItemLabel>Light shading</PropItemLabel>
                <Checkbox checked={ editorParams.lightShading } onChange={ LightShadingChangeHandler } />
            </PropItem>
            <PropItem>
                <PropItemLabel>Normal maps</PropItemLabel>
                <Checkbox checked={ editorParams.normalMaps } onChange={ NormalMapsChangeHandler } />
            </PropItem>
            <BlockTitle>Lights</BlockTitle>
            <PropItem>
                <PropItemLabel>Ambient intensity</PropItemLabel>
                <PropItemInput sourceValue={ map.lights.ambient.intensity } setSourceValue={ ( value ) => { setMap( { ...map, lights: { ...map.lights, ambient: { ...map.lights.ambient, intensity: parseFloat( value ) } } } ) }} />
            </PropItem>
            <PropItem>
                <PropItemLabel>Sun intensity</PropItemLabel>
                <PropItemInput sourceValue={ map.lights.directional.intensity } setSourceValue={ ( value ) => { setMap( { ...map, lights: { ...map.lights, directional: { ...map.lights.directional, intensity: parseFloat( value ) } } } ) }} />
            </PropItem>
            <PropItem>
                <PropItemLabel>Sun direction</PropItemLabel>
                <PropItemInputOneThirdWidth sourceValue={ map.lights.directional.direction.x } setSourceValue={ ( value ) => { setMap( { ...map, lights: { ...map.lights, directional: { ...map.lights.directional, direction: { ...map.lights.directional.direction, x: parseFloat( value ) } } } } ) }} />
                <PropItemInputOneThirdWidth sourceValue={ map.lights.directional.direction.y } setSourceValue={ ( value ) => { setMap( { ...map, lights: { ...map.lights, directional: { ...map.lights.directional, direction: { ...map.lights.directional.direction, y: parseFloat( value ) } } } } ) }} />
                <PropItemInputOneThirdWidth sourceValue={ map.lights.directional.direction.z } setSourceValue={ ( value ) => { setMap( { ...map, lights: { ...map.lights, directional: { ...map.lights.directional, direction: { ...map.lights.directional.direction, z: parseFloat( value ) } } } } ) }} />
            </PropItem>
        </TabWrapper>
    );

};
