
import { createContext, useState } from "react";

//

export const RightPanelContext = createContext<any>({
    activeTab: null,
    setActiveTab: () => null
});

export const RightPanelContextProvider = ( { children } : any ) => {

    const [ activeTab, setActiveTab ] = useState<string>( 'general' );

    return (
        <RightPanelContext.Provider value={{
            activeTab, setActiveTab
        }}>
            { children }
        </RightPanelContext.Provider>
    );

};
