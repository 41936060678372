
const subtractVectors = ( v1: number[], v2: number[] ) : number[] => {

    return [ v1[0] - v2[0], v1[1] - v2[1], v1[2] - v2[2] ];

};

const crossProduct = ( v1: number[], v2: number[] ) : number[] => {

    return [
        v1[1] * v2[2] - v1[2] * v2[1],
        v1[2] * v2[0] - v1[0] * v2[2],
        v1[0] * v2[1] - v1[1] * v2[0]
    ];

};

function normalizeVector ( v: number[] ) : number[] {

    const length = Math.sqrt( v[0] * v[0] + v[1] * v[1] + v[2] * v[2] );
    return [ v[0] / length, v[1] / length, v[2] / length ];

};

export const computeNormal = ( v1: number[], v2: number[], v3: number[] ) => {

    const edge1 = subtractVectors( v2, v1 );
    const edge2 = subtractVectors( v3, v1 );

    const normal = crossProduct( edge1, edge2 );

    return normalizeVector( normal );

};

export const getPixelValue = ( imageData: ImageData, width: number, height: number, x: number, y: number ) : number[] => {

    // Ensure x and y are within the bounds of the canvas
    if (x < 0 || x >= width || y < 0 || y >= height) {
        // throw new Error('Coordinates out of bounds');
        return [ 0, 0, 0 ];
    }

    // Get the integer and fractional parts of x and y
    const x1 = Math.floor(x);
    const x2 = Math.min(x1 + 1, width - 1);
    const y1 = Math.floor(y);
    const y2 = Math.min(y1 + 1, height - 1);

    const dx = x - x1;
    const dy = y - y1;

    // Get the image data for the surrounding pixels
    const data = imageData.data;

    // Helper function to get pixel values at (x, y)
    function getPixelValue ( x: number, y: number ) {
        const index = (y * width + x) * 4;
        return [
            data[index],     // Red
            data[index + 1], // Green
            data[index + 2], // Blue
            data[index + 3]  // Alpha
        ];
    }

    // Get pixel values
    const p11 = getPixelValue(x1, y1);
    const p21 = getPixelValue(x2, y1);
    const p12 = getPixelValue(x1, y2);
    const p22 = getPixelValue(x2, y2);

    // Interpolate between the four surrounding pixels
    const interpolate = ( p1: number, p2: number, weight: number ) => p1 + (p2 - p1) * weight;

    const interpolateColor = ( c1: number[], c2: number[], c3: number[], c4: number[] ) => [
        interpolate(interpolate(c1[0], c2[0], dx), interpolate(c3[0], c4[0], dx), dy),
        interpolate(interpolate(c1[1], c2[1], dx), interpolate(c3[1], c4[1], dx), dy),
        interpolate(interpolate(c1[2], c2[2], dx), interpolate(c3[2], c4[2], dx), dy),
        interpolate(interpolate(c1[3], c2[3], dx), interpolate(c3[3], c4[3], dx), dy)
    ];

    const interpolatedColor = interpolateColor(p11, p21, p12, p22);

    return interpolatedColor;

};

export const cloneArrayBuffer = ( buffer: ArrayBuffer ) : ArrayBuffer => {

    const clonedBuffer = new ArrayBuffer( buffer.byteLength );
    new Uint8Array( clonedBuffer ).set( new Uint8Array( buffer ) );

    return clonedBuffer;

};
