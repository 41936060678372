
import styled from "styled-components";
import { IoMdCloseCircleOutline } from "react-icons/io";

import { PopupWrapper } from "./PopupWrapper";
import { useContext, useEffect, useRef, useState } from "react";
import { useIndexedDB } from "../../hooks/IndexedDBHook";
import { IMap, MapContext } from "../../context/MapContext";
import { usePopupContext } from "../../context/PopupsContext";

//

const PopupContainer = styled.div`
    width: 500px;
    height: 400px;
`;

const PopupTitle = styled.div`
    font-size: 24px;
    color: #000;
    text-align: center;
    font-weight: bold;
`;

const MapsList = styled.div`
    position: relative;
    text-align: center;
    width: 100%;
    height: calc( 100% - 130px );
    margin-top: 35px;
    margin-bottom: 5px;
    font-size: 23px;
    overflow-y: auto;
`;

const NoMapsLabel = styled.div`
    position: absolute;
    width: 100%;
    text-align: center;
    top: 40%;
    color: #aaa;
    font-size: 15px;
    padding: 0px;
`;

const CloseBtn = styled(IoMdCloseCircleOutline)`
    position: absolute;
    top: 5px;
    right: 10px;
    font-size: 40px;
    cursor: pointer;
    color: #a55;
    transition: color 0.3s;
    &:hover {
        color: #f00;
    }
`;

interface LoadBtnProps {
    active: string;
};

const LoadBtn = styled.div<LoadBtnProps>`
    width: calc( 100% - 20px );
    padding: 15px 10px;
    background-color: #0a0;
    color: #fff;
    text-align: center;
    cursor: pointer;
    border-radius: 5px;
    margin-top: 10px;
    transition: background-color 0.3s;
    &:hover {
        background-color: #0f0;
    }
    ${({ active }) => ! active && `
        background-color: #aaa;
        cursor: default;
        &:hover {
            background-color: #aaa;
        }
    `}
`;

interface MapItemProps {
    $selected: boolean;
};

const MapItem = styled.div<MapItemProps>`
    padding: 10px;
    cursor: pointer;
    transition: background-color 0.3s;
    border-bottom: 1px solid #eee;
    background-color: ${(props) => props.$selected ? '#f0fff0' : '#fff'};
    ${props => ! props.$selected && `
        &:hover {
            background-color: #f0f0f0;
        }
    `}
`;

const MapItemName = styled.div`
    display: inline-block;
    float: left;
    font-size: 20px;
    padding: 10px;
`;

const MapItemDate = styled.div`
    display: inline-block;
    float: right;
    font-size: 13px;
    padding: 15px;
    color: #aaa;
`;

const ClearBoth = styled.div`
    clear: both;
`;

//

export const LoadMapPopup = () => {

    const POPUP_NAME = 'LoadMap';

    const { getDBItems } = useIndexedDB();
    const [ maps, setMaps ] = useState<IMap[]>([]);
    const { state, close } = usePopupContext( POPUP_NAME );
    const [ selectedMap, setSelectedMap ] = useState<string>( '' );
    const { loadMap } = useContext( MapContext );

    const loadMaps = async () => {

        const maps = await getDBItems( 'Maps' );
        setMaps( maps );

    };

    useEffect( () => {

        loadMaps();

    }, [ state ] );

    const MapItemClickHandler = ( mapName: string ) => {

        setSelectedMap( mapName );

    };

    const LoadBtnClickHandler = () => {

        if ( ! selectedMap ) return;

        loadMap( maps.find( ( map ) => map.name === selectedMap ) as IMap );
        close();

    };

    //

    return (
        <PopupWrapper name={ POPUP_NAME } >
            <PopupContainer>
                <PopupTitle>Load map</PopupTitle>
                <MapsList>
                    { ( maps.length ) ? (
                        maps.map( ( map, index ) => (
                            <MapItem key={ index } onClick={ () => { MapItemClickHandler( map.name ) } } $selected={ selectedMap === map.name } >
                                <MapItemName>{ map.name }</MapItemName>
                                <MapItemDate>{ map.createdAt.toLocaleDateString() }</MapItemDate>
                                <ClearBoth />
                             </MapItem>
                        ) )
                    ) : (
                        <NoMapsLabel>No maps yet</NoMapsLabel>
                    ) }
                </MapsList>
                <CloseBtn onClick={ close } />
                <LoadBtn active={ selectedMap } onClick={ LoadBtnClickHandler } >Load</LoadBtn>
            </PopupContainer>
        </PopupWrapper>
    );

};
