
import { useContext, useState } from "react";
import styled from "styled-components";

import { MapContext } from "../../../context/MapContext";

//

const TextureSelectorContainer = styled.div`
    position: absolute;
    bottom: 20px;
    right: 10px;
    width: 20px;
    height: 20px;
    z-index: 100;
`;

interface TextureSelectorProps {
    $img: string;
};

const TextureSelector = styled.div<TextureSelectorProps>`
    position: absolute;
    bottom: 0px;
    right: 0px;
    width: 100%;
    height: 100%;
    border: 1px solid #aaa;
    cursor: pointer;
    background-image: url( ${ ( { $img } ) => $img } );
    background-size: 100% 100%;
    &:hover {
        border: 1px solid #afa;
    }
`;

interface TextureSelectorDropListProps {
    $visible: boolean;
};

const TextureSelectorDropList = styled.div<TextureSelectorDropListProps>`
    position: absolute;
    bottom: 0px;
    right: -1px;
    width: 200px;
    max-height: 150px;
    border: 1px solid #aaa;
    background-color: #fff;
    transform: translateY( 100% );
    overflow-y: scroll;
    visibility: ${ ( { $visible } ) => $visible ? "visible" : "hidden" };
`;

const TextureSelectorDropListItem = styled.div`
    border-bottom: 1px solid #ccc;
    height: 40px;
    cursor: pointer;
    &:hover {
        background-color: #f0f0f0;
    }
`;

const TextureSelectorDropListItemPreview = styled.img`
    float: left;
    width: 30px;
    height: 30px;
    margin: 5px;
`;

const TextureSelectorDropListItemTitle = styled.div`
    float: left;
    width: calc( 100% - 50px );
    padding-top: 10px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    font-size: 12px;
    color: #aaa;
`;

//

export const TextureSelectDropdown = ( { onSelect, value, ...props }: { value: string, onSelect: ( textureId: string ) => void } ) => {

    const { map } = useContext( MapContext );

    const [ selectedTexture, setSelectedTexture ] = useState<string>( value );
    const [ textureSelectorVisible, setTextureSelectorVisible ] = useState<boolean>( false );

    const DropdownClickHandler = () => {

        setTextureSelectorVisible( true );

    };

    const DropdownMouseOut = () => {

        setTextureSelectorVisible( false );

    };

    const TextureSelectHandler = ( textureId: string ) => {

        setSelectedTexture( textureId );
        setTextureSelectorVisible( false );
        onSelect( textureId );

    };

    //

    return (
        <TextureSelectorContainer onMouseLeave={ DropdownMouseOut } { ...props } >
            <TextureSelector onClick={ DropdownClickHandler } $img={ map?.assets.textures[ selectedTexture ]?.preview || '' } />
            <TextureSelectorDropList $visible={ textureSelectorVisible } >
                {
                    Object.keys( map?.assets.textures || {} ).map( ( id, index ) => (
                        <TextureSelectorDropListItem key={ index } onClick={ TextureSelectHandler.bind( this, id ) } >
                            <TextureSelectorDropListItemPreview src={ map?.assets.textures[ id ].preview } />
                            <TextureSelectorDropListItemTitle>{ map?.assets.textures[ id ].name }</TextureSelectorDropListItemTitle>
                        </TextureSelectorDropListItem>
                    ) )
                }
            </TextureSelectorDropList>
        </TextureSelectorContainer>
    );

};
