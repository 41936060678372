
import { Vector3 } from "three";
import styled from "styled-components";

import { TabTitle, TabWrapper, BlockTitle, Block } from "../TabContent";
import { MapContext } from "../../../context/MapContext";
import { useContext } from "react";
import { EditorContext } from "../../../context/EditorContext";
import { Checkbox, Slider } from "@mui/material";
import { TexturePreviewImage } from "../../Other/TexturePreviewImage";
import { PropItem, PropItemInput, PropItemInputHalfWidth, PropItemLabel } from "../RightPanel";

//

interface ImageBtnProps {
    $active: boolean;
};

const ImageBtn = styled.div<ImageBtnProps>`
    display: flex;
    align-items: center;
    justify-content: center;
    float: left;
    width: 70px;
    height: 70px;
    margin: 10px 5px;
    background-color: #0a0;
    color: #fff;
    font-size: 12px;
    cursor: pointer;
    border-radius: 5px;
    border: 2px solid #aaa;
    &:hover {
        border-color: ${(props: ImageBtnProps) => ( props.$active ? '#ff0' : '#f88' )};
    }
    transition: background-color 0.3s;
    border-color: ${(props: ImageBtnProps) => ( props.$active ? '#ff0' : '#aaa' )};
    overflow: hidden;
`;

//

export const TerrainTabContent = ( { $active } : { $active: boolean }) => {

    const { map, setMap } = useContext( MapContext );
    const editorParams = useContext( EditorContext );

    const navGridVisibleChangeHandle = ( event: React.ChangeEvent<HTMLInputElement> ) => {

        editorParams.setNavGridVisible( event.target.checked );

    };

    const collisionGridVisibleChangeHandle = ( event: React.ChangeEvent<HTMLInputElement> ) => {

        editorParams.setCollisionGridVisible( event.target.checked );

    };

    const wireframeVisibleChangeHandle = ( event: React.ChangeEvent<HTMLInputElement> ) => {

        editorParams.setWireframeVisible( event.target.checked );

    };

    const selectMappingPaint = ( mode: number ) => {

        if ( ! map ) return;

        editorParams.setMousePosition( new Vector3( -100000, -100000, 0 ) );
        editorParams.setPaintMode( mode );

    };

    //

    if ( ! map ) return null;

    return (
        <TabWrapper $active={ $active } >
            <TabTitle>Terrain</TabTitle>
            <PropItem>
                <PropItemLabel>Show wireframe</PropItemLabel>
                <Checkbox onChange={ wireframeVisibleChangeHandle } value={ editorParams.wireframeVisible } />
            </PropItem>
            <PropItem>
                <PropItemLabel>Cell size</PropItemLabel>
                <PropItemInput sourceValue={ map.params.cellSize } setSourceValue={ ( value ) => { setMap( { ...map, params: { ...map.params, cellSize: parseInt( value ) } } ) } } />
            </PropItem>
            <PropItem>
                <PropItemLabel>Mapping size</PropItemLabel>
                <PropItemInputHalfWidth sourceValue={ map.mapping.width } setSourceValue={ ( value ) => { setMap( { ...map, mapping: { ...map.mapping, width: parseInt( value ) } } ) } } />
                <PropItemInputHalfWidth sourceValue={ map.mapping.height } setSourceValue={ ( value ) => { setMap( { ...map, mapping: { ...map.mapping, height: parseInt( value ) } } ) } } />
            </PropItem>
            <PropItem>
                <PropItemLabel>Texture tile size</PropItemLabel>
                <Slider value={ map.params.textureTileSize } onChange={ ( event: any, value: number | number[] ) => { setMap( { ...map, params: { ...map.params, textureTileSize: value as number } } ) } } max={ 100 } step={ 0.01 } aria-label="Default" valueLabelDisplay="auto" />
            </PropItem>
            <PropItem>
                <PropItemLabel>Noise factor</PropItemLabel>
                <Slider value={ map.params.noiseFactor } onChange={ ( event: any, value: number | number[] ) => { setMap( { ...map, params: { ...map.params, noiseFactor: value as number } } ) } } max={ 1 } step={ 0.01 } aria-label="Default" valueLabelDisplay="auto" />
            </PropItem>
            <PropItem>
                <PropItemLabel>Noise repeat</PropItemLabel>
                <Slider value={ map.params.noiseRepeat } onChange={ ( event: any, value: number | number[] ) => { setMap( { ...map, params: { ...map.params, noiseRepeat: value as number } } ) } } max={ 10 } step={ 0.01 } aria-label="Default" valueLabelDisplay="auto" />
            </PropItem>
            <BlockTitle>Elevation</BlockTitle>
            <Block>
                <ImageBtn onClick={ () => selectMappingPaint( 1 ) } $active={ editorParams.paintMode === 1 } >UP</ImageBtn>
                <ImageBtn onClick={ () => selectMappingPaint( 2 ) } $active={ editorParams.paintMode === 2 } >DOWN</ImageBtn>
                <ImageBtn onClick={ () => selectMappingPaint( 3 ) } $active={ editorParams.paintMode === 3 } >SMOOTH</ImageBtn>
            </Block>
            <BlockTitle>Texture</BlockTitle>
            <Block>
                <ImageBtn onClick={ () => selectMappingPaint( 10 ) } $active={ editorParams.paintMode === 10 } ><TexturePreviewImage width={ 70 } height={ 70 } textureId={ 0 } /></ImageBtn>
                <ImageBtn onClick={ () => selectMappingPaint( 11 ) } $active={ editorParams.paintMode === 11 } ><TexturePreviewImage width={ 70 } height={ 70 } textureId={ 1 } /></ImageBtn>
                <ImageBtn onClick={ () => selectMappingPaint( 12 ) } $active={ editorParams.paintMode === 12 } ><TexturePreviewImage width={ 70 } height={ 70 } textureId={ 2 } /></ImageBtn>
                <ImageBtn onClick={ () => selectMappingPaint( 13 ) } $active={ editorParams.paintMode === 13 } ><TexturePreviewImage width={ 70 } height={ 70 } textureId={ 3 } /></ImageBtn>
                <ImageBtn onClick={ () => selectMappingPaint( 14 ) } $active={ editorParams.paintMode === 14 } ><TexturePreviewImage width={ 70 } height={ 70 } textureId={ 4 } /></ImageBtn>
            </Block>
            <BlockTitle>Navigation Grid</BlockTitle>
            <PropItem>
                <PropItemLabel>Show nav grid</PropItemLabel>
                <Checkbox onChange={ navGridVisibleChangeHandle } value={ editorParams.navGridVisible } />
            </PropItem>
            <PropItem>
                <PropItemLabel>Cell size</PropItemLabel>
                <PropItemInputHalfWidth sourceValue={ map.navGrid.cellSize } setSourceValue={ ( value ) => { setMap( { ...map, navGrid: { ...map.navGrid, cellSize: parseInt( value ) } } ) } } />
            </PropItem>
            <Block>
                <ImageBtn onClick={ () => selectMappingPaint( 100 ) } $active={ editorParams.paintMode === 100 } >Add</ImageBtn>
                <ImageBtn onClick={ () => selectMappingPaint( 101 ) } $active={ editorParams.paintMode === 101 } >Remove</ImageBtn>
            </Block>
            <BlockTitle>Collide Grid</BlockTitle>
            <PropItem>
                <PropItemLabel>Show collision grid</PropItemLabel>
                <Checkbox onChange={ collisionGridVisibleChangeHandle } value={ editorParams.collisionGridVisible } />
            </PropItem>
            <PropItem>
                <PropItemLabel>Cell size</PropItemLabel>
                <PropItemInputHalfWidth sourceValue={ map.collisionGrid.cellSize } setSourceValue={ ( value ) => { setMap( { ...map, collisionGrid: { ...map.collisionGrid, cellSize: parseInt( value ) } } ) } } />
            </PropItem>
            <Block>
                <ImageBtn onClick={ () => selectMappingPaint( 102 ) } $active={ editorParams.paintMode === 102 } >Add</ImageBtn>
                <ImageBtn onClick={ () => selectMappingPaint( 103 ) } $active={ editorParams.paintMode === 103 } >Remove</ImageBtn>
            </Block>
        </TabWrapper>
    );

};
