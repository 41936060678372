
import { ChangeEvent, KeyboardEvent, KeyboardEventHandler, useEffect, useState } from "react";
import styled from "styled-components";
import { CiCircleCheck } from "react-icons/ci";
import { RxCross2 } from "react-icons/rx";

//

const InputWrapper = styled.div`
    position: relative;
    height: 100%;
`;

const InputField = styled.input`
    position: relative;
    font-size: 12px;
    color: #000;
    padding: 10px;
    width: calc( 100% - 20px );
    outline: none;
`;

const InputApplyBtn = styled(CiCircleCheck)`
    position: absolute;
    right: 40px;
    top: 50%;
    transform: translateY( -50% );
    font-size: 25px;
    color: #0a0;
    cursor: pointer;
    &:hover {
        color: #0f0;
    }
`;

const InputDiscardBtn = styled(RxCross2)`
    position: absolute;
    right: 10px;
    top: 50%;
    transform: translateY( -50% );
    font-size: 25px;
    color: #a00;
    cursor: pointer;
    &:hover {
        color: #f00;
    }
`;

//

export const InputWithApply = ( { sourceValue, setSourceValue, ...props } : { sourceValue: string | number, setSourceValue: ( value: string ) => void } ) => {

    const [ value, setValue ] = useState( '' );
    const [ originalValue, setOriginalValue ] = useState( '' );
    const [ isChanged, setIsChanged ] = useState( false );

    const onChangeHandler = ( event: ChangeEvent<HTMLInputElement> ) : void => {

        setValue( event.target.value );
        setIsChanged( event.target.value !== originalValue );

    };

    const ApplyBtnClickHandler = () : void => {

        setOriginalValue( value );
        setIsChanged( false );
        setSourceValue( value );

    };

    const DiscardBtnClickHandler = () : void => {

        setValue( originalValue );
        setIsChanged( false );

    };

    const InputKeyPressHandler = ( event: KeyboardEvent<HTMLInputElement> ) : void => {

        if ( event.key === 'Enter' ) ApplyBtnClickHandler();
        if ( event.key === 'Escape' ) DiscardBtnClickHandler();

    };

    useEffect( () => {

        setOriginalValue( sourceValue.toString() );
        setValue( sourceValue.toString() );

    }, [ sourceValue ]);

    //

    return (
        <InputWrapper { ...props } >
            <InputField value={ value } onChange={ onChangeHandler } onKeyDown={ InputKeyPressHandler } />
            { ( isChanged ) ? (
                <>
                    <InputApplyBtn onClick={ ApplyBtnClickHandler } />
                    <InputDiscardBtn onClick={ DiscardBtnClickHandler } />
                </>
            ) : null }
        </InputWrapper>
    );

};
