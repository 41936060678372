
import { createContext, Dispatch, useContext, useEffect, useReducer, useState } from 'react';

//

const initalState = {
    'CreateMap':                false,
    'ImportMap':                false,
    'LoadMap':                  false,
    'CreateNewAssetCategory':   false,
    'AddAssetsModel':           false,
    'AddAssetsTexture':         false,
};

//

const popupReducer = ( state: { [key:string]: boolean }, action: { type: string} ) => {

    return { ...state, [ action.type.split('_')[1] ]: action.type.split('_')[0] === 'Open' };

};

interface IPopupState {
    state:              { [key:string]: boolean };
    dispatch:           Dispatch<{ type: string }>;
    anyPopupOpened:     boolean;
};

export const PopupContext = createContext<IPopupState>({
    state:              {},
    dispatch:           () => null,
    anyPopupOpened:     false
});

export const PopupContextProvider = ( { children } : any ) => {

    const [ state, dispatch ] = useReducer( popupReducer, initalState );
    const [ anyPopupOpened, setAnyPopupOpened ] = useState( false );

    useEffect( () => {

        let newAnyPopupOpened = false;

        for ( const key in state ) {

            if ( state[ key ] ) {

                newAnyPopupOpened = true;
                break;

            }

        }

        setAnyPopupOpened( newAnyPopupOpened );

    }, [ state ] );

    //

    return (
        <PopupContext.Provider value={{
            state, dispatch, anyPopupOpened
        }} >
            { children }
        </PopupContext.Provider>
    );

};

export const usePopupContext = ( popupName?: string ) => {

    const { state, dispatch, anyPopupOpened } = useContext( PopupContext );

    if ( popupName !== undefined && state[ popupName ] === undefined ) {

        throw new Error( 'Popup ' + popupName + ' not found' );

    }

    return {
        state: state[ popupName || '' ],
        anyPopupOpened: anyPopupOpened,
        close: dispatch.bind( null, { type: 'Close_' + popupName } ),
        open: dispatch.bind( null, { type: 'Open_' + popupName } )
    };

};
