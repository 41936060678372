
import { createContext, useCallback, useState } from "react";
import { toast } from "react-toastify";

import { useIndexedDB } from "../hooks/IndexedDBHook";
import { useAssetManager } from "../hooks/AssetManagerHook";

//

export interface IMapModelAsset {
    id:                     string;
    name:                   string;
    buffer:                 ArrayBuffer;
    preview:                string;
    triangles:              number;
    textureId:              string;
};

export interface IMapTextureAsset {
    id:                     string;
    name:                   string;
    buffer:                 ArrayBuffer;
    preview:                string;
};

export interface IMapModelsAssetCategory {
    title:                  string;
    list:                   { [key:string]: IMapModelAsset };
    order:                  number;
};

export interface IMapObject {
    id:                     string;
    modelId:                string;
    matrix:                 number[];
    textureId:              string;
};

export interface IMap {
    id:                     string;
    name:                   string;
    params: {
        width:              number;
        height:             number;
        cellSize:           number;
        textureTileSize:    number;
        noiseFactor:        number;
        noiseRepeat:        number;
    };
    mapping: {
        width:              number;
        height:             number;
        data:               Float32Array;
    };
    navGrid: {
        cellSize:           number;
        data:               Float32Array;
    };
    collisionGrid: {
        cellSize:           number;
        data:               Float32Array;
    };
    assets: {
        models:             { [key:string]: IMapModelsAssetCategory };
        textures:           { [key:string]: IMapTextureAsset };
    };
    lights: {
        ambient:            { color: number, intensity: number };
        directional:        { color: number, intensity: number, direction: { x: number, y: number, z: number } };
    };
    objects:                IMapObject[];
    units:                  any[];
    createdAt:              Date;
};

interface MapContextProps {
    map:                    IMap | null;
    setMap:                 ( map: null | IMap | ( ( prev: IMap | null ) => IMap | null ) ) => void;
    loadMap:                ( map: IMap ) => void;
    saveMap:                () => void;
    getModelById:           ( id: string ) => IMapModelAsset | null;
    mappingCanvas:          HTMLCanvasElement;
    setMappingCanvas:       ( canvas: HTMLCanvasElement ) => void;
    mappingNeedsUpdate:     boolean;
    setMappingNeedsUpdate:  ( value: boolean ) => void;
};

export const MapContext = createContext<MapContextProps>({
    map: null,
    setMap: () => {},
    loadMap: ( map ) => {},
    saveMap: () => {},
    getModelById: ( id ) => null,
    mappingCanvas: document.createElement( 'canvas' ),
    setMappingCanvas: () => null,
    mappingNeedsUpdate: false,
    setMappingNeedsUpdate: () => null
});

export const MapContextProvider = ( { children } : any ) => {

    const { loadModel, loadTexture } = useAssetManager();
    const [ map, setMap ] = useState<IMap | null>( null );
    const [ mappingCanvas, setMappingCanvas ] = useState<HTMLCanvasElement>( document.createElement( 'canvas' ) );
    const [ mappingNeedsUpdate, setMappingNeedsUpdate ] = useState<boolean>( false );
    const { updateDBItem } = useIndexedDB();

    const saveMap = useCallback( () => {

        if ( ! map ) return;
        updateDBItem( 'Maps', map.id, map );
        toast( 'Map saved!', {
            type: 'success',
            position: 'bottom-left',
            autoClose: 1000
        });

    }, [ map ] );

    const loadMap = async ( map: IMap ) => {

        map.createdAt = ( typeof map.createdAt === "number" ? new Date( map.createdAt as unknown as number ) : map.createdAt);

        // load models

        for ( const category in map.assets.models ) {

            const models = map.assets.models[ category ].list;

            for ( const modelId in models ) {

                const model = models[ modelId ];
                await loadModel( model.id, model.name, model.buffer );

            }

        }

        // load textures

        for ( const textureName in map.assets.textures ) {

            const texture = map.assets.textures[ textureName ];
            await loadTexture( texture.id, texture.name, texture.buffer );

        }

        setMap( map );

    };

    const getModelById = ( id: string ) => {

        if ( ! map ) return null;

        for ( const category in map.assets.models ) {

            const models = map.assets.models[ category ].list;

            for ( const modelId in models ) {

                if ( modelId === id ) return models[ modelId ];

            }

        }

        return null;

    };

    //

    return (
        <MapContext.Provider value={{
            map, setMap,
            saveMap, loadMap, getModelById,
            mappingCanvas, setMappingCanvas,
            mappingNeedsUpdate, setMappingNeedsUpdate
        }}>
            { children }
        </MapContext.Provider>
    );

};
