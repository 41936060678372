
import styled from "styled-components";
import { useContext, useState } from "react";
import { IoMdImages } from "react-icons/io";
import { TbCube } from "react-icons/tb";

import { TabTitle, TabWrapper } from "../TabContent";
import { CreateNewAssetCategoryPopup } from "./CreateNewAssetCategoryPopup";
import { usePopupContext } from "../../../context/PopupsContext";
import { IMap, MapContext } from "../../../context/MapContext";
import { MdDeleteOutline, MdEdit } from "react-icons/md";
import { AddAssetsModelsPopup } from "./AddAssetsModelPopup";
import { EditorContext } from "../../../context/EditorContext";
import { AddAssetsTexturesPopup } from "./AddAssetsTexturePopup";

//

const SubCategoryWrapper = styled.div`
    position: absolute;
    right: 10px;
    top: 0;
`;

interface SubCategoryItemProps {
    $active: boolean;
};

const SubCategoryItem = styled.div<SubCategoryItemProps>`
    display: inline-block;
    padding: 5px 10px;
    color: #fff;
    font-size: 20px;
    padding: 10px 10px 5px 10px;
    margin-left: 5px;
    cursor: pointer;
    transition: background-color 0.3s;
    &:hover {
        background-color: rgba( 255, 30, 30, 0.7 );
    }
    ${({ $active }) => $active && `
        background-color: rgba( 255, 30, 30, 0.5 );
    `}
`;

interface SubCategoryContentProps {
    $active: boolean;
};

const SubCategoryContent = styled.div<SubCategoryContentProps>`
    position: relative;
    display: none;
    width: 100%;
    height: calc( 100% - 50px );
    overflow-y: auto;
    ${({ $active }) => $active && `
        display: block;
    `}
`;

const ItemsCategory = styled.div`
    margin-bottom: 10px;
`;

const AssetsList = styled.div`
    width: 100%;
    height: calc( 100% - 50px );
    overflow-y: auto;
`;

const ItemsCategoryTitle = styled.div`
    position: relative;
    font-size: 15px;
    padding: 20px 0px 5px 20px;
    color: #fff;
    border-top: 1px solid rgba( 255, 255, 255, 0.05 );
`;

const ItemCategoryRemoveBtn = styled.div`
    position: absolute;
    display: none;
    right: 10px;
    top: 10px;
    padding: 5px;
    color: #fff;
    cursor: pointer;
    transition: color 0.3s;
    font-size: 20px;
    &:hover {
        color: #f00;
    }
    ${ ItemsCategory }:hover & {
        display: block;
    }
`;

const ItemCategoryEditBtn = styled.div`
    position: absolute;
    display: none;
    right: 40px;
    top: 10px;
    padding: 5px;
    color: #fff;
    cursor: pointer;
    transition: color 0.3s;
    font-size: 20px;
    &:hover {
        color: #f00;
    }
    ${ ItemsCategory }:hover & {
        display: block;
    }
`;

const ModelsList = styled.div`
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(70px, 1fr));
    grid-template-rows: max-content;
    justify-items: center;
    box-sizing: border-box;
    gap: 10px;
    padding: 10px;
`;

const TexturesList = styled.div`
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(70px, 1fr));
    grid-template-rows: max-content;
    justify-items: center;
    box-sizing: border-box;
    gap: 10px;
    padding: 30px;
`;

const AddAssetsBtn = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 7px 10px;
    color: white;
    font-size: 12px;
    border-radius: 5px;
    background-color: rgba( 255, 30, 30, 0.2 );
    &:hover {
        background-color: rgba( 255, 30, 30, 0.5 );
    }
    transition: background-color 0.3s;
    cursor: pointer;
`;

const AddNewCategoryBtn = styled.div`
    position: absolute;
    width: calc( 100% - 20px );
    bottom: 0;
    padding: 10px;
    color: white;
    cursor: pointer;
    margin-top: 5px;
    text-align: center;
    background-color: rgba( 255, 30, 30, 0.5 );
    margin-bottom: 0px;
    transition: background-color 0.3s;
    &:hover {
        background-color: rgba( 255, 30, 30, 0.7 );
    }
`;

interface ItemCardProps {
    $active: boolean;
};

const ItemCard = styled.div<ItemCardProps>`
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: row;
    width: 50px;
    height: 50px;
    display: flex;
    padding: 10px;
    background-color: rgba( 255, 255, 255, 0.1 );
    cursor: pointer;
    border-radius: 5px;
    border: 2px solid #aaa;
    &:hover {
        border-color: ${(props: ItemCardProps) => ( props.$active ? '#ff0' : '#f88' )};
    }
    transition: background-color 0.3s;
    border-color: ${(props: ItemCardProps) => ( props.$active ? '#ff0' : '#aaa' )};
`;

interface ItemCardPreviewProps {
    $src: string;
};

const ItemCardPreview = styled.div<ItemCardPreviewProps>`
    position: relative;
    width: 50px;
    height: 50px;
    background-image: url( ${ ( { $src } ) => $src } );
    background-size: cover;
    background-position: center;
    border-radius: 5px;
    background-position: center center;
    background-size: 200%;
`;

//

export const AssetsTabContent = ( { $active } : { $active: boolean }) => {

    const { open: OpenCreateAssetCategoryPopup } = usePopupContext( 'CreateNewAssetCategory' );
    const { open: OpenAddAssetsModelsPopup } = usePopupContext( 'AddAssetsModel' );
    const { open: OpenAddAssetsTexturesPopup } = usePopupContext( 'AddAssetsTexture' );

    const { map, setMap } = useContext( MapContext );
    const editorParams = useContext( EditorContext );
    const [ selectedCategory, setSelectedCategory ] = useState<string>( '' );
    const [ activeSubCategory, setActiveSubCategory ] = useState<string>( 'objects' );

    //

    const AddNewCategoryBtnClickHandler = () => {

        OpenCreateAssetCategoryPopup();

    };

    const AddNewTextureBtnClickHandler = () => {

        OpenAddAssetsTexturesPopup();

    };

    const RemoveCategoryBtnClickHandler = ( key: string ) => {

        setMap( ( prev: IMap | null ) => {

            if ( ! prev ) return prev;

            const newMap = { ...prev };
            delete newMap.assets.models[ key ];

            return newMap;

        });

    };

    const EditCategoryBtnClickHandler = ( category: string ) => {

        setSelectedCategory( category );
        OpenAddAssetsModelsPopup();

    };

    const ItemClickHandler = ( id: string ) => {

        editorParams.setPlaceMode( id );

    };

    const SubCategoryItemClickHandler = ( category: string ) => {

        setActiveSubCategory( category );

    };

    //

    return (
        <TabWrapper $active={ $active } >
            <TabTitle>
                Assets
                <SubCategoryWrapper>
                    <SubCategoryItem $active={ activeSubCategory === 'objects' } onClick={ SubCategoryItemClickHandler.bind( this, 'objects' ) } ><TbCube /></SubCategoryItem>
                    <SubCategoryItem $active={ activeSubCategory === 'textures' } onClick={ SubCategoryItemClickHandler.bind( this, 'textures' ) } ><IoMdImages /></SubCategoryItem>
                </SubCategoryWrapper>
            </TabTitle>
            <SubCategoryContent $active={ activeSubCategory === 'objects' } >
                <AssetsList>
                    {
                        Object.keys( map?.assets.models ?? {} ).map( ( key ) => (
                            <ItemsCategory key={ key }>
                                <ItemsCategoryTitle>
                                    { map?.assets.models[ key ].title }
                                    <ItemCategoryRemoveBtn onClick={ RemoveCategoryBtnClickHandler.bind( this, key ) }><MdDeleteOutline/></ItemCategoryRemoveBtn>
                                    <ItemCategoryEditBtn onClick={ EditCategoryBtnClickHandler.bind( this, key ) } ><MdEdit/></ItemCategoryEditBtn>
                                </ItemsCategoryTitle>
                                <ModelsList key={ key } >
                                    {
                                        Object.keys( map?.assets.models[ key ].list ?? {} ).length ? (
                                            Object.keys( map?.assets.models[ key ].list ?? {} ).map( ( id, index ) => (
                                                <ItemCard key={ index } $active={ editorParams.placeMode === id } onClick={ ItemClickHandler.bind( this, id ) } >
                                                    <ItemCardPreview $src={ map?.assets.models[ key ].list[ id ].preview ?? '' } />
                                                </ItemCard>
                                            ) )
                                        ) : (
                                            <AddAssetsBtn onClick={ EditCategoryBtnClickHandler.bind( this, key ) } >Add assets</AddAssetsBtn>
                                        )
                                    }
                                </ModelsList>
                            </ItemsCategory>
                        ) )
                    }
                </AssetsList>
                <AddNewCategoryBtn onClick={ AddNewCategoryBtnClickHandler }>Add New Category</AddNewCategoryBtn>
            </SubCategoryContent>

            <SubCategoryContent $active={ activeSubCategory === 'textures' } >
                <TexturesList>
                    {
                        Object.keys( map?.assets.textures || {} ).map( ( id, index ) => (
                            <ItemCard key={ index } $active={ false } onClick={ () => {} } >
                                <ItemCardPreview $src={ map?.assets.textures[ id ].preview || '' } />
                            </ItemCard>
                        ) )
                    }
                </TexturesList>
                <AddNewCategoryBtn onClick={ AddNewTextureBtnClickHandler }>Add Texture</AddNewCategoryBtn>
            </SubCategoryContent>

            <CreateNewAssetCategoryPopup />
            <AddAssetsModelsPopup category={ selectedCategory } onClose={ setSelectedCategory.bind( this, '' ) } />
            <AddAssetsTexturesPopup />
        </TabWrapper>
    );

};
