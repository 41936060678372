
import { useContext, useEffect, useRef } from "react";
import { CanvasTexture, CompressedArrayTexture, CompressedTexture, DataTexture, DoubleSide, FloatType, GLSL3, RepeatWrapping, RGBAFormat, SRGBColorSpace, Vector2, Vector3 } from "three";
import { extend } from "@react-three/fiber";
import { shaderMaterial } from "@react-three/drei";

import { EditorContext } from "../../../context/EditorContext";
import { IMap, MapContext } from "../../../context/MapContext";
import { computeNormal } from "../../../utils/Math.util";

//

declare global {
    namespace JSX {
        interface IntrinsicElements {
            terrainMaterial: any;
        }
    }
};

//

const vertexShader = /* glsl */ `
    #include <common>
    #include <logdepthbuf_pars_vertex>

    out vec2 vUv;
    out vec3 fragPos;

    uniform sampler2D mapping;

    void main () {

        vUv = uv;

        float elevation = ( texture( mapping, vec2( 0.99 * vUv.x / 3.0 + 0.01 / 6.0, 0.99 * vUv.y + 0.01 / 2.0 ) ).r - 0.5 ) * 170.0;

        vec4 mvPosition = modelMatrix * vec4( position.x, position.y, elevation, 1.0 );

        fragPos = vec3( mvPosition );
        mvPosition = viewMatrix * mvPosition;

        float isPerspective = float (projectionMatrix[ 2 ][ 3 ] == - 1.0);
        float polygonOffsetFactor = 0.001;
        float polygonOffsetUnits = - 1.0;
        if ( isPerspective == 1.0 ) mvPosition.z += polygonOffsetFactor * mvPosition.z;
        // mvPosition.z += polygonOffsetUnits;

        gl_Position = projectionMatrix * mvPosition;

        #include <logdepthbuf_vertex>

    }
`;

const fragmentShader = /* glsl */ `
    #include <common>
    #include <logdepthbuf_pars_fragment>

    in vec2 vUv;

    in vec3 fragPos;
    uniform sampler2DArray diffuseAtlas;
    uniform sampler2DArray normalAtlas;
    uniform sampler2D noise;
    uniform sampler2D mapping;
    uniform vec2 mousePosition;
    uniform vec2 mapSize;
    uniform float textureTileSize;
    uniform float normalMaps;
    uniform float shading;
    uniform float brushSize;
    uniform float brushStrength;
    uniform float brushGradient;
    uniform float noiseFactor;
    uniform float noiseRepeat;
    uniform vec3 lightDir;
    uniform float dirLightIntensity;
    uniform float ambientIntensity;

    uniform sampler2D navGridTexture;
    uniform float navGridCellSize;
    uniform float navGirdVisible;

    uniform sampler2D collisionGridTexture;
    uniform float collisionGridCellSize;
    uniform float collisionGridVisible;

    layout(location = 0) out lowp vec4 pc_fragColor;

    vec3 linearToSrgb(vec3 linear)
    {
        vec3 srgb;
        for (int i = 0; i < 3; i++) {
            if (linear[i] <= 0.0031308) {
                srgb[i] = linear[i] * 12.92;
            } else {
                srgb[i] = 1.055 * pow(linear[i], 1.0 / 2.4) - 0.055;
            }
        }
        return srgb;
    }

    void main () {

        vec3 texelNormal = texture( mapping, vec2( 0.99 * vUv.x / 3.0 + 0.01 / 6.0 + 0.666, vUv.y * 0.99 + 0.01 / 2.0 ) ).rgb * 2.0 - 1.0;
        vec3 absNormal = abs( texelNormal.xzy );

        vec2 uv;

        if ( absNormal.x > absNormal.y && absNormal.x > absNormal.z ) {

            uv = fragPos.yz;

        } else if ( absNormal.y > absNormal.z ) {

            uv = fragPos.zx;

        } else {

            uv = fragPos.xy;

        }

        vec3 pNormal = vec3( 0.0 );

        if ( shading > 0.5 ) {

            pNormal = texelNormal.xzy;

        }

        vec2 tuv = textureTileSize * vec2( uv.y, uv.x );

        vec4 rNoiseValue = texture( noise, vUv * noiseRepeat );
        tuv.x += ( rNoiseValue.r - 0.5 ) * noiseFactor;
        tuv.y += ( rNoiseValue.g - 0.5 ) * noiseFactor;

        vec4 w1 = texture( mapping, vec2( 0.99 * vUv.x / 3.0 + 0.01 / 6.0, vUv.y * 0.99 + 0.01 / 2.0 ) );
        vec4 w2 = texture( mapping, vec2( 0.99 * vUv.x / 3.0 + 0.01 / 6.0 + 0.3333, vUv.y * 0.99 + 0.01 / 2.0 ) );

        vec2 weights[5] = vec2[5]( vec2( 0.0, w1.g ), vec2( 1.0, w1.b ), vec2( 2.0, w2.r ), vec2( 3.0, w2.g ), vec2( 4.0, w2.b ) );

        vec2 maxWeightA = weights[0];

        for ( int i = 1; i < 5; i ++ ) {

            maxWeightA = mix( maxWeightA, weights[ i ], step( maxWeightA.y, weights[ i ].y ) );

        }

        vec2 maxWeightB = vec2( -1.0, -1.0 );

        for ( int i = 0; i < 5; i ++ ) {

            maxWeightB = mix( maxWeightB, weights[ i ], step( maxWeightB.y, weights[ i ].y ) * step( 0.001, abs( weights[ i ].x - maxWeightA.x ) ) );

        }

        int textureIndexes[2] = int[2]( int( maxWeightA.x ), int( maxWeightB.x ) );
        float textureWeights[2] = float[2]( maxWeightA.y, 1.0 - maxWeightA.y );

        vec4 t1 = texture( diffuseAtlas, vec3( tuv, textureIndexes[0] ) ) * ( 0.3 * rNoiseValue.r + 0.7 );
        vec4 t2 = texture( diffuseAtlas, vec3( tuv, textureIndexes[1] ) ) * ( 0.3 * rNoiseValue.g + 0.7 );

        vec3 nt1 = texture( normalAtlas, vec3( tuv, textureIndexes[0] ) ).rgb;
        nt1 = normalize( nt1 * 2.0 - 1.0 );

        vec3 nt2 = texture( normalAtlas, vec3( tuv, textureIndexes[1] ) ).rgb;
        nt2 = normalize( nt2 * 2.0 - 1.0 );

        vec3 nNormal = vec3( 0.0 );

        if ( normalMaps > 0.5 ) {

            nNormal = textureWeights[0] * nt1 + textureWeights[1] * nt2;

        }

        vec3 normal = normalize( 0.5 * pNormal + 0.25 * nNormal + 0.25 * ( rNoiseValue.rgb - 1.0 ) );

        pc_fragColor = vec4( textureWeights[0] * t1.rgb + textureWeights[1] * t2.rgb, 1.0 );

        if ( normalMaps > 0.5 || shading > 0.5 ) {

            pc_fragColor.rgb *= max( 0.0, dot( normal, normalize( lightDir ) ) ) * dirLightIntensity + ambientIntensity;
            pc_fragColor.rgb = linearToSrgb( pc_fragColor.rgb );

        }

        //

        if ( navGirdVisible > 0.5 ) {

            vec3 gridColor = vec3( 0.3, 1.0, 0.3 );
            vec3 forbiddenGridColor = vec3( 1.0, 0.0, 0.0 );
            vec3 greenGridColor = vec3( 0.0, 1.0, 0.0 );
            vec2 gridUV = vUv * mapSize;

            float lineWidth = navGridCellSize;
            float cellSize = navGridCellSize;
            float lineLength = navGridCellSize / 60.0;
            float lineOffset = - 0.0;
            float aaEdgeWidth = 0.1;

            lineWidth = cellSize - lineWidth;
            lineLength = cellSize - lineLength;

            float modX = mod( gridUV.x - lineOffset, cellSize );
            float modY = mod( gridUV.y - lineOffset, cellSize );

            float lineX1 = smoothstep( lineWidth, lineWidth + aaEdgeWidth, modX );
            float lineX2 = 1.0 - smoothstep( cellSize + lineOffset / 2.0 - lineWidth, cellSize + lineOffset / 2.0 - lineWidth + aaEdgeWidth, modX );
            float lineX = lineX1 + lineX2;

            float lineX3 = smoothstep( lineLength, lineLength + aaEdgeWidth, mod( gridUV.y, cellSize ) );
            float lineX4 = 1.0 - smoothstep( cellSize + lineOffset - lineLength, cellSize + lineOffset - lineLength + aaEdgeWidth, mod( gridUV.y, cellSize ) );
            lineX *= lineX3 + lineX4;

            float lineY1 = smoothstep( lineWidth, lineWidth + aaEdgeWidth, modY );
            float lineY2 = 1.0 - smoothstep( cellSize + lineOffset / 2.0 - lineWidth, cellSize + lineOffset / 2.0 - lineWidth + aaEdgeWidth, modY );
            float lineY = max( lineY1, lineY2 );

            float lineY3 = smoothstep( lineLength, lineLength + aaEdgeWidth, mod( gridUV.x, cellSize ) );
            float lineY4 = 1.0 - smoothstep( cellSize + lineOffset - lineLength, cellSize + lineOffset - lineLength + aaEdgeWidth, mod( gridUV.x, cellSize ) );
            lineY *= lineY3 + lineY4;

            float line = clamp( lineX + lineY, 0.0, 0.6 );
            vec3 color = mix( gridColor, pc_fragColor.rgb, 1.0 - line );

            float isForbidden = texture( navGridTexture, vUv ).r;

            pc_fragColor.rgb = color + vec3( 0.5 * isForbidden, 0.0, 0.0 );

        }

        if ( collisionGridVisible > 0.5 ) {

            vec3 gridColor = vec3( 0.3, 1.0, 0.3 );
            vec3 forbiddenGridColor = vec3( 1.0, 0.0, 0.0 );
            vec3 greenGridColor = vec3( 0.0, 1.0, 0.0 );
            vec2 gridUV = vUv * mapSize;

            float lineWidth = collisionGridCellSize;
            float cellSize = collisionGridCellSize;
            float lineLength = collisionGridCellSize / 60.0;
            float lineOffset = - 0.0;
            float aaEdgeWidth = 0.1;

            lineWidth = cellSize - lineWidth;
            lineLength = cellSize - lineLength;

            float modX = mod( gridUV.x - lineOffset, cellSize );
            float modY = mod( gridUV.y - lineOffset, cellSize );

            float lineX1 = smoothstep( lineWidth, lineWidth + aaEdgeWidth, modX );
            float lineX2 = 1.0 - smoothstep( cellSize + lineOffset / 2.0 - lineWidth, cellSize + lineOffset / 2.0 - lineWidth + aaEdgeWidth, modX );
            float lineX = lineX1 + lineX2;

            float lineX3 = smoothstep( lineLength, lineLength + aaEdgeWidth, mod( gridUV.y, cellSize ) );
            float lineX4 = 1.0 - smoothstep( cellSize + lineOffset - lineLength, cellSize + lineOffset - lineLength + aaEdgeWidth, mod( gridUV.y, cellSize ) );
            lineX *= lineX3 + lineX4;

            float lineY1 = smoothstep( lineWidth, lineWidth + aaEdgeWidth, modY );
            float lineY2 = 1.0 - smoothstep( cellSize + lineOffset / 2.0 - lineWidth, cellSize + lineOffset / 2.0 - lineWidth + aaEdgeWidth, modY );
            float lineY = max( lineY1, lineY2 );

            float lineY3 = smoothstep( lineLength, lineLength + aaEdgeWidth, mod( gridUV.x, cellSize ) );
            float lineY4 = 1.0 - smoothstep( cellSize + lineOffset - lineLength, cellSize + lineOffset - lineLength + aaEdgeWidth, mod( gridUV.x, cellSize ) );
            lineY *= lineY3 + lineY4;

            float line = clamp( lineX + lineY, 0.0, 0.6 );
            vec3 color = mix( gridColor, pc_fragColor.rgb, 1.0 - line );

            float isForbidden = texture( collisionGridTexture, vUv ).r;

            pc_fragColor.rgb = color + vec3( 0.5 * isForbidden, 0.0, 0.0 );

        }

        float dist = length( ( vUv - mousePosition ) * mapSize );
        float brush = mix( brushGradient, 1.0, ( brushSize - dist ) / 2.0 / brushSize );
        float isBrush = max( 0.0, 1.0 - step( brushSize, dist ) ) * brush;
        pc_fragColor.rgb = mix( pc_fragColor.rgb, vec3( 1.0, 0.0, 0.0 ), brushStrength * isBrush );

        #include <logdepthbuf_fragment>

    }
`;

const TerrainMaterial = shaderMaterial(
    {
        diffuseAtlas:           null,
        normalAtlas:            null,
        mapping:                null,
        noise:                  null,
        mousePosition:          new Vector2(),
        mapSize:                new Vector2(),
        lightDir:               new Vector3(),
        ambientIntensity:       0,
        dirLightIntensity:      0,
        textureTileSize:        1,
        wireframeVisible:       0,
        normalMaps:             0,
        shading:                0,
        brushSize:              0,
        brushStrength:          0,
        brushGradient:          0,
        noiseFactor:            0,
        noiseRepeat:            0,

        navGirdVisible:         0,
        navGridCellSize:        0,
        navGridTexture:         null,

        collisionGridVisible:     0,
        collisionGridCellSize:    null,
        collisionGridTexture:     null
    },
    vertexShader,
    fragmentShader
);

extend( { TerrainMaterial } );

//

export const TerrainShaderMaterial = ( { diffuseAtlas, normalAtlas, noise } : { diffuseAtlas: CompressedArrayTexture | null; normalAtlas: CompressedArrayTexture | null, noise: CompressedTexture | null } ) => {

    const materialRef = useRef<any>();
    const { map, setMap, mappingCanvas, setMappingNeedsUpdate } = useContext( MapContext );
    const editorParams = useContext( EditorContext );

    const mappingChannels = 7;
    const ctx = useRef<CanvasRenderingContext2D>( mappingCanvas.getContext( '2d' ) );
    const navMapTexture = useRef<DataTexture | null>( null );
    const collideMapTexture = useRef<DataTexture | null>( null );
    const mappingTexture = useRef<CanvasTexture>( new CanvasTexture( mappingCanvas ) );

    //

    const updateNormals = ( x: number, y: number, w: number, h: number ) => {

        if ( ! map ) return;

        const ctx = mappingCanvas.getContext( '2d' );
        const imageData = ctx?.getImageData( 0, 0, map.mapping.width, map.mapping.height );
        if ( ! imageData ) return;

        const normals: number[][][] = [];
        const vNormals: number[][][] = [];

        const dx = Math.max( 1, Math.floor( map.mapping.width / ( map.params.width / map.params.cellSize ) ) );
        const dy = Math.max( 1, Math.floor( map.mapping.height / ( map.params.height / map.params.cellSize ) ) );

        // compute real normals

        const cellSizeInMappingX = Math.round( map.mapping.width / ( map.params.width / map.params.cellSize ) );
        const cellSizeInMappingY = Math.round( map.mapping.height / ( map.params.height / map.params.cellSize ) );
        const vx = Math.round( x / cellSizeInMappingX - 1 ) * cellSizeInMappingX;
        const vy = Math.round( y / cellSizeInMappingY - 1 ) * cellSizeInMappingY;

        w = Math.ceil( w / cellSizeInMappingX + 1 ) * cellSizeInMappingX;
        h = Math.ceil( h / cellSizeInMappingY + 1 ) * cellSizeInMappingY;

        for ( let i = vx; i <= vx + w; i += dx ) {

            for ( let j = vy; j <= vy + h; j += dy ) {

                const x1A = map.params.width * ( i / map.mapping.width - 0.5 );
                const y1A = map.params.height * ( j / map.mapping.height - 0.5 );
                const z1A = map.mapping.data[ mappingChannels * ( i + j * map.mapping.width ) + 0 ];

                const x2A = map.params.width * ( ( i + dx ) / map.mapping.width - 0.5 );
                const y2A = map.params.height * ( j / map.mapping.height - 0.5 );
                const z2A = map.mapping.data[ mappingChannels * ( i + dx + j * map.mapping.width ) + 0 ];

                const x3A = map.params.width * ( i / map.mapping.width - 0.5 );
                const y3A = map.params.height * ( ( j + dy ) / map.mapping.height - 0.5 );
                const z3A = map.mapping.data[ mappingChannels * ( i + ( j + dy ) * map.mapping.width ) + 0 ];

                const normalA = computeNormal( [ x1A, y1A, z1A ], [ x2A, y2A, z2A ], [ x3A, y3A, z3A ] );

                for ( let k = 0; k <= dx; k ++ ) {

                    for ( let p = 0; p <= dy; p ++ ) {

                        vNormals[ i + k + ( j + p ) * map.mapping.width ] = normals[ i + j * map.mapping.width ] || [];
                        vNormals[ i + k + ( j + p ) * map.mapping.width ].push( normalA );

                        vNormals[ i + k + dx + ( j + p ) * map.mapping.width ] = normals[ i + dx + j * map.mapping.width ] || [];
                        vNormals[ i + k + dx + ( j + p ) * map.mapping.width ].push( normalA );

                        vNormals[ i + k + ( j + dy + p ) * map.mapping.width ] = normals[ i + ( j + dy ) * map.mapping.width ] || [];
                        vNormals[ i + k + ( j + dy + p ) * map.mapping.width ].push( normalA );

                    }

                }

                //

                const x1B = map.params.width * ( i / map.mapping.width - 0.5 );
                const y1B = map.params.height * ( ( j + dy ) / map.mapping.height - 0.5 );
                const z1B = map.mapping.data[ mappingChannels * ( i + ( j + dy ) * map.mapping.width ) + 0 ];

                const x2B = map.params.width * ( ( i + dx ) / map.mapping.width - 0.5 );
                const y2B = map.params.height * ( j / map.mapping.height - 0.5 );
                const z2B = map.mapping.data[ mappingChannels * ( i + dx + j * map.mapping.width ) + 0 ];

                const x3B = map.params.width * ( ( i + dx ) / map.mapping.width - 0.5 );
                const y3B = map.params.height * ( ( j + dy ) / map.mapping.height - 0.5 );
                const z3B = map.mapping.data[ mappingChannels * ( i + dx + ( j + dy ) * map.mapping.width ) + 0 ];

                const normalB = computeNormal( [ x1B, y1B, z1B ], [ x2B, y2B, z2B ], [ x3B, y3B, z3B ] );

                for ( let k = 0; k <= dx; k ++ ) {

                    for ( let p = 0; p <= dy; p ++ ) {

                        vNormals[ i + k + ( j + p + dy ) * map.mapping.width ] = normals[ i + ( j + dy ) * map.mapping.width ] || [];
                        vNormals[ i + k + ( j + p + dy ) * map.mapping.width ].push( normalB );

                        vNormals[ i + k + dx + ( j + p ) * map.mapping.width ] = normals[ i + dx + j * map.mapping.width ] || [];
                        vNormals[ i + k + dx + ( j + p ) * map.mapping.width ].push( normalB );

                        vNormals[ i + k + dx + ( j + p + dy ) * map.mapping.width ] = normals[ i + dx + ( j + dy ) * map.mapping.width ] || [];
                        vNormals[ i + k + dx + ( j + p + dy ) * map.mapping.width ].push( normalB );

                    }

                }

            }

        }

        // compute detailed normals

        for ( let i = vx; i <= vx + w; i ++ ) {

            for ( let j = vy; j <= vy + h; j ++ ) {

                const x1A = map.params.width * ( i / map.mapping.width - 0.5 );
                const y1A = map.params.height * ( j / map.mapping.height - 0.5 );
                const z1A = map.mapping.data[ mappingChannels * ( i + j * map.mapping.width ) + 0 ];

                const x2A = map.params.width * ( ( i + 1 ) / map.mapping.width - 0.5 );
                const y2A = map.params.height * ( j / map.mapping.height - 0.5 );
                const z2A = map.mapping.data[ mappingChannels * ( i + 1 + j * map.mapping.width ) + 0 ];

                const x3A = map.params.width * ( i / map.mapping.width - 0.5 );
                const y3A = map.params.height * ( ( j + 1 ) / map.mapping.height - 0.5 );
                const z3A = map.mapping.data[ mappingChannels * ( i + ( j + 1 ) * map.mapping.width ) + 0 ];

                const normalA = computeNormal( [ x1A, y1A, z1A ], [ x2A, y2A, z2A ], [ x3A, y3A, z3A ] );

                normals[ i + j * map.mapping.width ] = normals[ i + j * map.mapping.width ] || [];
                normals[ i + j * map.mapping.width ].push( normalA );

                normals[ i + 1 + j * map.mapping.width ] = normals[ i + 1 + j * map.mapping.width ] || [];
                normals[ i + 1 + j * map.mapping.width ].push( normalA );

                normals[ i + ( j + 1 ) * map.mapping.width ] = normals[ i + ( j + 1 ) * map.mapping.width ] || [];
                normals[ i + ( j + 1 ) * map.mapping.width ].push( normalA );

                //

                const x1B = map.params.width * ( i / map.mapping.width - 0.5 );
                const y1B = map.params.height * ( ( j + 1 ) / map.mapping.height - 0.5 );
                const z1B = map.mapping.data[ mappingChannels * ( i + ( j + 1 ) * map.mapping.width ) + 0 ];

                const x2B = map.params.width * ( ( i + 1 ) / map.mapping.width - 0.5 );
                const y2B = map.params.height * ( j / map.mapping.height - 0.5 );
                const z2B = map.mapping.data[ mappingChannels * ( i + 1 + j * map.mapping.width ) + 0 ];

                const x3B = map.params.width * ( ( i + 1 ) / map.mapping.width - 0.5 );
                const y3B = map.params.height * ( ( j + 1 ) / map.mapping.height - 0.5 );
                const z3B = map.mapping.data[ mappingChannels * ( i + 1 + ( j + 1 ) * map.mapping.width ) + 0 ];

                const normalB = computeNormal( [ x1B, y1B, z1B ], [ x2B, y2B, z2B ], [ x3B, y3B, z3B ] );

                normals[ i + ( j + 1 ) * map.mapping.width ] = normals[ i + ( j + 1 ) * map.mapping.width ] || [];
                normals[ i + ( j + 1 ) * map.mapping.width ].push( normalB );

                normals[ i + 1 + j * map.mapping.width ] = normals[ i + 1 + j * map.mapping.width ] || [];
                normals[ i + 1 + j * map.mapping.width ].push( normalB );

                normals[ i + 1 + ( j + 1 ) * map.mapping.width ] = normals[ i + 1 + ( j + 1 ) * map.mapping.width ] || [];
                normals[ i + 1 + ( j + 1 ) * map.mapping.width ].push( normalB );

            }

        }

        //

        const imageData1 = ctx?.getImageData( 2 * map.mapping.width, 0, map.mapping.width, map.mapping.height );
        if ( ! imageData1 ) return;

        for ( let i = vx; i <= vx + w; i ++ ) {

            for ( let j = vy; j <= vy + h; j ++ ) {

                const normal = { x: 0, y: 0, z: 0 };

                for ( let k = 0; k < normals[ i + j * map.mapping.width ].length; k ++ ) {

                    normal.x += normals[ i + j * map.mapping.width ][ k ][ 0 ];
                    normal.y += normals[ i + j * map.mapping.width ][ k ][ 1 ];
                    normal.z += normals[ i + j * map.mapping.width ][ k ][ 2 ];

                }

                if ( vNormals[ i + j * map.mapping.width ] ) {

                    for ( let k = 0; k < vNormals[ i + j * map.mapping.width ].length; k ++ ) {

                        normal.x += vNormals[ i + j * map.mapping.width ][ k ][ 0 ];
                        normal.y += vNormals[ i + j * map.mapping.width ][ k ][ 1 ];
                        normal.z += vNormals[ i + j * map.mapping.width ][ k ][ 2 ];

                    }

                }

                const length = Math.sqrt( normal.x * normal.x + normal.y * normal.y + normal.z * normal.z );

                const finalNormal = [ normal.x / length, normal.y / length, normal.z / length ];

                imageData1.data[ 4 * ( i + j * map.mapping.width ) + 0 ] = Math.floor( 127 + 127 * finalNormal[ 0 ] );
                imageData1.data[ 4 * ( i + j * map.mapping.width ) + 1 ] = Math.floor( 127 + 127 * finalNormal[ 1 ] );
                imageData1.data[ 4 * ( i + j * map.mapping.width ) + 2 ] = Math.floor( 127 + 127 * finalNormal[ 2 ] );

            }

        }

        ctx?.putImageData( imageData1, 2 * map.mapping.width, 0 );

    };

    const changeElevation = ( x: number, y: number, radius: number, gradient: number, delta: number ) => {

        if ( ! map ) return;

        const radiusX = Math.round( radius / map.params.width * map.mapping.width );
        const radiusY = Math.round( radius / map.params.height * map.mapping.height );
        radius = Math.max( radiusX, radiusY );

        for ( let i = - radiusX; i <= radiusX; i ++ ) {

            for ( let j = - radiusY; j <= radiusY; j ++ ) {

                if ( Math.sqrt( i * i + j * j ) < radius ) {

                    const cx = x + i;
                    const cy = y + j;
                    map.mapping.data[ ( cx + cy * map.mapping.width ) * mappingChannels + 0 ] += delta;

                }

            }

        }

        const ctx = mappingCanvas.getContext( '2d' );
        const imageData = ctx?.getImageData( 0, 0, map.mapping.width, map.mapping.height );
        if ( ! imageData ) return;

        for ( let i = 0; i < map.mapping.width; i ++ ) {

            for ( let j = 0; j < map.mapping.height; j ++ ) {

                imageData.data[ 4 * ( i + j * map.mapping.width ) + 0 ] = map.mapping.data[ mappingChannels * ( i + map.mapping.width * j ) + 0 ];

            }

        }

        ctx?.putImageData( imageData, 0, 0 );

        const normalUpdateRadius = Math.max( radius, map.params.cellSize );
        updateNormals( x - normalUpdateRadius, y - normalUpdateRadius, 2 * normalUpdateRadius, 2 * normalUpdateRadius );

    };

    const changeTexture = ( x: number, y: number, radius: number, gradient: number, delta: number, textureId: number ) => {

        if ( ! map ) return;

        const radiusX = Math.round( radius / map.params.width * map.mapping.width );
        const radiusY = Math.round( radius / map.params.height * map.mapping.height );
        radius = Math.max( radiusX, radiusY );

        for ( let i = - radiusX; i <= radiusX; i ++ ) {

            for ( let j = - radiusY; j <= radiusY; j ++ ) {

                if ( Math.sqrt( i * i + j * j ) < radius ) { // add proper support of elliptical brush

                    const cx = x + i;
                    const cy = y + j;
                    const distFromCenter = Math.sqrt( i * i + j * j );
                    const deltaValue = delta * ( ( 1 - distFromCenter / radius ) + gradient * distFromCenter / radius );

                    const remainingBefore = 255 - map.mapping.data[ mappingChannels * ( cx + cy * map.mapping.width ) + textureId ];
                    map.mapping.data[ mappingChannels * ( cx + cy * map.mapping.width ) + textureId ] = Math.min( 255, Math.max( 0, map.mapping.data[ mappingChannels * ( cx + cy * map.mapping.width ) + textureId ] + deltaValue ) );
                    const remaining = 255 - map.mapping.data[ mappingChannels * ( cx + cy * map.mapping.width ) + textureId ];

                    for ( let k = 2; k < 7; k ++ ) {

                        if ( textureId === k ) continue;

                        if ( remainingBefore === 0 ) continue;
                        map.mapping.data[ mappingChannels * ( cx + cy * map.mapping.width ) + k ] = Math.min( 255, Math.max( 0, map.mapping.data[ mappingChannels * ( cx + cy * map.mapping.width ) + k ] / remainingBefore * remaining ) );

                    }

                }

            }

        }

        const ctx = mappingCanvas.getContext( '2d' );
        const imageData = ctx?.getImageData( 0, 0, 3 * map.mapping.width, map.mapping.height );
        if ( ! imageData ) return;

        for ( let i = 0; i < map.mapping.width; i ++ ) {

            for ( let j = 0; j < map.mapping.height; j ++ ) {

                imageData.data[ 4 * ( i + 3 * j * map.mapping.width ) + 1 ] = Math.round( map.mapping.data[ mappingChannels * ( i + j * map.mapping.width ) + 2 ] );
                imageData.data[ 4 * ( i + 3 * j * map.mapping.width ) + 2 ] = Math.round( map.mapping.data[ mappingChannels * ( i + j * map.mapping.width ) + 3 ] );

                imageData.data[ 4 * ( i + 3 * j * map.mapping.width + map.mapping.width ) + 0 ] = Math.round( map.mapping.data[ mappingChannels * ( i + j * map.mapping.width ) + 4 ] );
                imageData.data[ 4 * ( i + 3 * j * map.mapping.width + map.mapping.width ) + 1 ] = Math.round( map.mapping.data[ mappingChannels * ( i + j * map.mapping.width ) + 5 ] );
                imageData.data[ 4 * ( i + 3 * j * map.mapping.width + map.mapping.width ) + 2 ] = Math.round( map.mapping.data[ mappingChannels * ( i + j * map.mapping.width ) + 6 ] );

            }

        }

        ctx?.putImageData( imageData, 0, 0 );

    };

    const smoothElevation = ( x: number, y: number, radius: number, delta: number ) => {

        if ( ! map ) return;

        let sum = 0;
        let points = 0;

        for ( let i = - radius; i <= radius; i ++ ) {

            for ( let j = - radius; j <= radius; j ++ ) {

                if ( Math.sqrt( i * i + j * j ) < radius ) {

                    const cx = x + i;
                    const cy = y + j;
                    sum += map.mapping.data[ ( cy * map.mapping.width + cx ) * mappingChannels + 0 ];
                    points ++;

                }

            }

        }

        const avgElevation = sum / points;

        //

        for ( let i = - radius; i <= radius; i ++ ) {

            for ( let j = - radius; j <= radius; j ++ ) {

                if ( Math.sqrt( i * i + j * j ) < radius ) {

                    const cx = x + i;
                    const cy = y + j;
                    map.mapping.data[ ( cy * map.mapping.width + cx ) * mappingChannels + 0 ] = avgElevation * 0.1 + map.mapping.data[ ( cy * map.mapping.width + cx ) * mappingChannels + 0 ] * 0.9;

                }

            }

        }

        //

        const ctx = mappingCanvas.getContext( '2d' );
        const imageData = ctx?.getImageData( 0, 0, map.mapping.width, map.mapping.height ).data!;

        for ( let i = 0; i < imageData.length; i ++ ) {

            imageData[ 4 * i + 0 ] = map.mapping.data[ mappingChannels * i + 0 ];

        }

        ctx?.putImageData( new ImageData( imageData, map.mapping.width, map.mapping.height ), 0, 0 );

        const normalUpdateRadius = Math.max( radius, map.params.cellSize );
        updateNormals( x - normalUpdateRadius, y - normalUpdateRadius, 2 * normalUpdateRadius, 2 * normalUpdateRadius );

    };

    const changeNavGrid = ( x: number, y: number, radius: number, value: number ) => {

        if ( ! map ) return;
        if ( ! navMapTexture.current ) return;

        radius = Math.round( radius / map.navGrid.cellSize );
        const data = ( navMapTexture.current as DataTexture ).image.data as unknown as Float32Array;
        const navGridWidth = Math.ceil( map.params.width / map.navGrid.cellSize );
        const navGridHeight = Math.ceil( map.params.height / map.navGrid.cellSize );

        x = Math.floor( x * navGridWidth );
        y = Math.floor( ( 1 - y ) * navGridHeight );

        for ( let i = - radius; i <= radius; i ++ ) {

            for ( let j = - radius; j <= radius; j ++ ) {

                if ( Math.sqrt( i * i + j * j ) < radius ) {

                    data[ 4 * ( ( y + j ) * navGridWidth + x + i ) + 0 ] = value;

                }

            }

        }

        navMapTexture.current.needsUpdate = true;
        materialRef.current.needsUpdate = true;

    };

    const changeCollisionGrid = ( x: number, y: number, radius: number, value: number ) => {

        if ( ! map ) return;
        if ( ! collideMapTexture.current ) return;

        radius = Math.round( radius / map.collisionGrid.cellSize );
        const data = ( collideMapTexture.current as DataTexture ).image.data as unknown as Float32Array;
        const collideGridWidth = Math.ceil( map.params.width / map.collisionGrid.cellSize );
        const collideGridHeight = Math.ceil( map.params.height / map.collisionGrid.cellSize );

        x = Math.floor( x * collideGridWidth );
        y = Math.floor( ( 1 - y ) * collideGridHeight );

        for ( let i = - radius; i <= radius; i ++ ) {

            for ( let j = - radius; j <= radius; j ++ ) {

                if ( Math.sqrt( i * i + j * j ) < radius ) {

                    data[ 4 * ( ( y + j ) * collideGridWidth + x + i ) + 0 ] = value;

                }

            }

        }

        collideMapTexture.current.needsUpdate = true;
        materialRef.current.needsUpdate = true;

    };

    const drawMapping = () => {

        if ( ! map ) return;
        if ( ! ctx.current ) return;

        if ( ! editorParams.mouseKeyDown[ 0 ] ) return;

        const x = Math.floor( editorParams.mousePosition.x * map.mapping.width );
        const y = Math.floor( ( 1 - editorParams.mousePosition.y ) * map.mapping.height );
        const radius = editorParams.brushSize;
        const strength = editorParams.brushStrength;
        const gradient = editorParams.brushEdges;

        if ( editorParams.paintMode === 1 ) {

            changeElevation( x, y, radius, gradient, 1 * strength );

        } else if ( editorParams.paintMode === 2 ) {

            changeElevation( x, y, radius, gradient, -1 * strength );

        } else if ( editorParams.paintMode === 3 ) {

            smoothElevation( x, y, radius, 0 );

        } else if ( editorParams.paintMode >= 10 && editorParams.paintMode < 100 ) {

            changeTexture( x, y, radius, gradient, 10 * strength, editorParams.paintMode - 10 + 2 );

        } else if ( editorParams.paintMode === 100 ) {

            changeNavGrid( editorParams.mousePosition.x, 1 - editorParams.mousePosition.y, radius, 0 );

            setMap( ( prev: IMap | null ) => {

                if ( ! prev ) return prev;
                if ( ! navMapTexture.current?.image.data.buffer ) return prev;

                const buffer = navMapTexture.current?.image.data as unknown as Float32Array;
                return { ...prev, navGrid: { ...prev.navGrid, data: buffer } };

            });

            return;

        } else if ( editorParams.paintMode === 101 ) {

            changeNavGrid( editorParams.mousePosition.x, 1 - editorParams.mousePosition.y, radius, 1 );

            setMap( ( prev: IMap | null ) => {

                if ( ! prev ) return prev;
                if ( ! navMapTexture.current?.image.data.buffer ) return prev;

                const buffer = navMapTexture.current?.image.data as unknown as Float32Array;
                return { ...prev, navGrid: { ...prev.navGrid, data: buffer } };

            });

            return;

        } else if ( editorParams.paintMode === 102 ) {

            changeCollisionGrid( editorParams.mousePosition.x, 1 - editorParams.mousePosition.y, radius, 0 );

            setMap( ( prev: IMap | null ) => {

                if ( ! prev ) return prev;
                if ( ! collideMapTexture.current?.image.data.buffer ) return prev;

                const buffer = collideMapTexture.current?.image.data as unknown as Float32Array;
                return { ...prev, collideGrid: { ...prev.collisionGrid, data: buffer } };

            });

            return;

        } else if ( editorParams.paintMode === 103 ) {

            changeCollisionGrid( editorParams.mousePosition.x, 1 - editorParams.mousePosition.y, radius, 1 );

            setMap( ( prev: IMap | null ) => {

                if ( ! prev ) return prev;
                if ( ! collideMapTexture.current?.image.data.buffer ) return prev;

                const buffer = collideMapTexture.current?.image.data as unknown as Float32Array;
                return { ...prev, collideGrid: { ...prev.collisionGrid, data: buffer } };

            });

            return;

        }

        mappingTexture.current.needsUpdate = true;
        setMappingNeedsUpdate( true );

    };

    //

    useEffect( () => {

        if ( ! map ) return;
        if ( ! ctx.current ) return;

        mappingCanvas.width = 3 * map.mapping.width;
        mappingCanvas.height = map.mapping.height;

        ctx.current.fillStyle = 'rgba( 0, 0, 0, 1 )';
        ctx.current.fillRect( 0, 0, 3 * map.mapping.width, map.mapping.height );

        const mappingData = ctx.current.getImageData( 0, 0, 3 * map.mapping.width, map.mapping.height );

        for ( let i = 0; i < map.mapping.width; i ++ ) {

            for ( let j = 0; j < map.mapping.height; j ++ ) {

                mappingData.data[ 4 * ( i + 3 * j * map.mapping.width ) + 0 ] = map.mapping.data[ mappingChannels * ( i + j * map.mapping.width ) + 0 ];
                mappingData.data[ 4 * ( i + 3 * j * map.mapping.width ) + 1 ] = Math.round( map.mapping.data[ mappingChannels * ( i + j * map.mapping.width ) + 2 ] );
                mappingData.data[ 4 * ( i + 3 * j * map.mapping.width ) + 2 ] = Math.round( map.mapping.data[ mappingChannels * ( i + j * map.mapping.width ) + 3 ] );
                mappingData.data[ 4 * ( i + 3 * j * map.mapping.width ) + 3 ] = 255;

                mappingData.data[ 4 * ( i + 3 * j * map.mapping.width + map.mapping.width ) + 0 ] = Math.round( map.mapping.data[ mappingChannels * ( i + j * map.mapping.width ) + 4 ] );
                mappingData.data[ 4 * ( i + 3 * j * map.mapping.width + map.mapping.width ) + 1 ] = Math.round( map.mapping.data[ mappingChannels * ( i + j * map.mapping.width ) + 5 ] );
                mappingData.data[ 4 * ( i + 3 * j * map.mapping.width + map.mapping.width ) + 2 ] = Math.round( map.mapping.data[ mappingChannels * ( i + j * map.mapping.width ) + 6 ] );
                mappingData.data[ 4 * ( i + 3 * j * map.mapping.width + map.mapping.width ) + 3 ] = 255;

            }

        }

        ctx.current.putImageData( mappingData, 0, 0 );

        mappingTexture.current = new CanvasTexture( mappingCanvas );
        materialRef.current.uniforms.mapping.value = mappingTexture.current;

        mappingTexture.current.needsUpdate = true;
        materialRef.current.needsUpdate = true;

        setMappingNeedsUpdate( true );

    }, [] );

    useEffect( () => {

        if ( ! map ) return;

        if ( materialRef.current ) {

            if ( ! editorParams.paintMode ) {

                materialRef.current.uniforms.mousePosition.value.set( -2, -2 );

            } else {

                materialRef.current.uniforms.mousePosition.value.copy( editorParams.mousePosition );
                drawMapping();

            }

        }

    }, [ editorParams.mousePosition, editorParams.paintMode ] );

    useEffect( () => {

        if ( ! map ) return;
        if ( ! materialRef ) return;
        if ( ! ctx.current ) return;

        let mappingData = ctx.current.getImageData( 0, 0, mappingCanvas.width, mappingCanvas.height );

        const osCanvas = document.createElement( 'canvas' );
        const osCtx = osCanvas.getContext( '2d' );
        if ( ! osCtx ) return;
        osCanvas.width = mappingCanvas.width;
        osCanvas.height = mappingCanvas.height;
        osCtx.putImageData( mappingData, 0, 0 );

        mappingCanvas.width = 3 * map.mapping.width;
        mappingCanvas.height = map.mapping.height;

        ctx.current.fillStyle = 'rgba( 0, 0, 0, 1 )';
        ctx.current.fillRect( 0, 0, mappingCanvas.width, mappingCanvas.height );
        ctx.current.drawImage( osCanvas, 0, 0, osCanvas.width, osCanvas.height, 0, 0, mappingCanvas.width, mappingCanvas.height );

        mappingData = ctx.current.getImageData( 0, 0, mappingCanvas.width, mappingCanvas.height );

        const newData = new Float32Array( map.mapping.width * map.mapping.height * mappingChannels );

        for ( let i = 0; i < map.mapping.width; i ++ ) {

            for ( let j = 0; j < map.mapping.height; j ++ ) {

                newData[ mappingChannels * ( i + j * map.mapping.width ) + 0 ] = mappingData.data[ 4 * ( i + 3 * j * map.mapping.width ) + 0 ];

                newData[ mappingChannels * ( i + j * map.mapping.width ) + 2 ] = mappingData.data[ 4 * ( i + 3 * j * map.mapping.width ) + 1 ];
                newData[ mappingChannels * ( i + j * map.mapping.width ) + 3 ] = mappingData.data[ 4 * ( i + 3 * j * map.mapping.width ) + 2 ];
                newData[ mappingChannels * ( i + j * map.mapping.width ) + 4 ] = mappingData.data[ 4 * ( i + 3 * j * map.mapping.width + map.mapping.width ) + 0 ];
                newData[ mappingChannels * ( i + j * map.mapping.width ) + 5 ] = mappingData.data[ 4 * ( i + 3 * j * map.mapping.width + map.mapping.width ) + 1 ];
                newData[ mappingChannels * ( i + j * map.mapping.width ) + 6 ] = mappingData.data[ 4 * ( i + 3 * j * map.mapping.width + map.mapping.width ) + 2 ];

            }

        }

        setMap({ ...map, mapping: { ...map.mapping, data: newData } });

        mappingTexture.current = new CanvasTexture( mappingCanvas );
        materialRef.current.uniforms.mapping.value = mappingTexture.current;

        mappingTexture.current.needsUpdate = true;
        materialRef.current.needsUpdate = true;

        setMappingNeedsUpdate( true );
        updateNormals( 0, 0, map.mapping.width, map.mapping.height );

    }, [ map?.mapping.width, map?.mapping.height ] );

    useEffect( () => {

        if ( ! map ) return;
        if ( ! materialRef ) return;

        materialRef.current.uniforms.mapSize.value.set( map.params.width, map.params.height );
        materialRef.current.uniforms.textureTileSize.value = 1 / map.params.textureTileSize;

    }, [ map?.params.width, map?.params.height, map?.params.textureTileSize ] );

    useEffect( () => {

        if ( ! map ) return;
        if ( ! materialRef ) return;

        materialRef.current.uniforms.navGridCellSize.value = map.navGrid.cellSize;

        const cellsW = Math.ceil( map.params.width / map.navGrid.cellSize );
        const cellsH = Math.ceil( map.params.height / map.navGrid.cellSize );

        if ( 4 * cellsW * cellsH !== map.navGrid.data.length ) {

            const data = new Float32Array( 4 * cellsW * cellsH ).fill( 1 );
            navMapTexture.current = new DataTexture( data, cellsW, cellsH, RGBAFormat, FloatType );

        } else {

            navMapTexture.current = new DataTexture( map.navGrid.data, cellsW, cellsH, RGBAFormat, FloatType );

        }

        materialRef.current.uniforms.navGridTexture.value = navMapTexture.current;
        navMapTexture.current.needsUpdate = true;
        materialRef.current.needsUpdate = true;

    }, [ map?.navGrid.cellSize, map?.mapping.width, map?.mapping.height ] );

    useEffect( () => {

        if ( ! map ) return;
        if ( ! materialRef ) return;

        materialRef.current.uniforms.collisionGridCellSize.value = map.collisionGrid.cellSize;

        const cellsW = Math.ceil( map.params.width / map.collisionGrid.cellSize );
        const cellsH = Math.ceil( map.params.height / map.collisionGrid.cellSize );

        if ( 4 * cellsW * cellsH !== map.collisionGrid.data.length ) {

            const data = new Float32Array( 4 * cellsW * cellsH ).fill( 1 );
            collideMapTexture.current = new DataTexture( data, cellsW, cellsH, RGBAFormat, FloatType );

        } else {

            collideMapTexture.current = new DataTexture( map.collisionGrid.data, cellsW, cellsH, RGBAFormat, FloatType );

        }

        materialRef.current.uniforms.collisionGridTexture.value = collideMapTexture.current;
        collideMapTexture.current.needsUpdate = true;
        materialRef.current.needsUpdate = true;

    }, [ map?.collisionGrid.cellSize, map?.mapping.width, map?.mapping.height ] );

    useEffect( () => {

        if ( materialRef.current ) {

            materialRef.current.uniforms.diffuseAtlas.value = diffuseAtlas;
            ( diffuseAtlas as CompressedArrayTexture ).colorSpace = SRGBColorSpace;
            ( diffuseAtlas as CompressedArrayTexture ).wrapS = RepeatWrapping;
            ( diffuseAtlas as CompressedArrayTexture ).wrapT = RepeatWrapping;
            // ( diffuseAtlas as CompressedArrayTexture ).colorSpace = SRGBColorSpace;
            materialRef.current.needsUpdate = true;

        }

    }, [ diffuseAtlas ] );

    useEffect( () => {

        if ( materialRef.current ) {

            materialRef.current.uniforms.normalAtlas.value = normalAtlas;
            ( normalAtlas as CompressedArrayTexture ).wrapS = RepeatWrapping;
            ( normalAtlas as CompressedArrayTexture ).wrapT = RepeatWrapping;
            // ( normalAtlas as CompressedArrayTexture ).colorSpace = SRGBColorSpace;
            materialRef.current.needsUpdate = true;

        }

    }, [ normalAtlas ] );

    useEffect( () => {

        if ( materialRef.current ) {

            materialRef.current.uniforms.noise.value = noise;
            ( noise as CompressedArrayTexture ).wrapS = RepeatWrapping;
            ( noise as CompressedArrayTexture ).wrapT = RepeatWrapping;
            materialRef.current.needsUpdate = true;

        }

    }, [ noise ] );

    //

    useEffect( () => {

        if ( materialRef.current ) {

            materialRef.current.uniforms.ambientIntensity.value = map?.lights.ambient.intensity;
            materialRef.current.needsUpdate = true;

        }

    }, [ map?.lights.ambient.intensity ] );

    useEffect( () => {

        if ( materialRef.current ) {

            materialRef.current.uniforms.lightDir.value = new Vector3( map?.lights.directional.direction.x, map?.lights.directional.direction.y, map?.lights.directional.direction.z );
            materialRef.current.needsUpdate = true;

        }

    }, [ map?.lights.directional.direction ] );

    useEffect( () => {

        if ( materialRef.current ) {

            materialRef.current.uniforms.dirLightIntensity.value = map?.lights.directional.intensity;
            materialRef.current.needsUpdate = true;

        }

    }, [ map?.lights.directional.intensity ] );

    useEffect( () => {

        if ( materialRef.current ) {

            materialRef.current.uniforms.noiseFactor.value = map?.params.noiseFactor || 0;
            materialRef.current.needsUpdate = true;

        }

    }, [ map?.params.noiseFactor ] );

    useEffect( () => {

        if ( materialRef.current ) {

            materialRef.current.uniforms.noiseRepeat.value = map?.params.noiseRepeat || 0;
            materialRef.current.needsUpdate = true;

        }

    }, [ map?.params.noiseRepeat ] );

    useEffect( () => {

        if ( materialRef.current ) {

            materialRef.current.uniforms.navGirdVisible.value = editorParams.navGridVisible ? 1 : 0;
            materialRef.current.needsUpdate = true;

        }

    }, [ editorParams.navGridVisible ] );

    useEffect( () => {

        if ( materialRef.current ) {

            materialRef.current.uniforms.collisionGridVisible.value = editorParams.collisionGridVisible ? 1 : 0;
            materialRef.current.needsUpdate = true;

        }

    }, [ editorParams.collisionGridVisible ] );

    useEffect( () => {

        if ( materialRef.current ) {

            materialRef.current.uniforms.normalMaps.value = editorParams.normalMaps ? 1 : 0;
            materialRef.current.needsUpdate = true;

        }

    }, [ editorParams.normalMaps ] );

    useEffect( () => {

        if ( materialRef.current ) {

            materialRef.current.uniforms.shading.value = editorParams.lightShading ? 1 : 0;
            materialRef.current.needsUpdate = true;

        }

    }, [ editorParams.lightShading ] );

    useEffect( () => {

        if ( materialRef.current ) {

            materialRef.current.uniforms.brushSize.value = editorParams.brushSize;
            materialRef.current.needsUpdate = true;

        }

    }, [ editorParams.brushSize ] );

    useEffect( () => {

        if ( materialRef.current ) {

            materialRef.current.uniforms.brushStrength.value = editorParams.brushStrength;
            materialRef.current.needsUpdate = true;

        }

    }, [ editorParams.brushStrength ] );

    useEffect( () => {

        if ( materialRef.current ) {

            materialRef.current.uniforms.brushGradient.value = editorParams.brushEdges;
            materialRef.current.needsUpdate = true;

        }

    }, [ editorParams.brushEdges ] );

    //

    return (
        <terrainMaterial
            ref={ materialRef }
            attach="material"
            glslVersion={ GLSL3 }
            side={ DoubleSide }
            transparent={ false }
            wireframe={ false }
        />
    );

};
