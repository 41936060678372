
import styled from 'styled-components';
import { CiFileOn, CiEdit, CiCirclePlus } from "react-icons/ci";
import { useContext } from 'react';

import { MapContext } from '../../context/MapContext';
import { usePopupContext } from '../../context/PopupsContext';

//

const HeaderWrapper = styled.div`
    position: absolute;
    top: 0px;
    left: 0px;
    z-index: 1;
    margin: 10px;
    background-color: rgba( 0, 0, 0, 0.5 );
    backdrop-filter: blur( 10px );
    border-radius: 10px;
`;

const MapTitle = styled.div`
    position: relative;
    float: left;
    margin-top: 14px;
    margin-left: 20px;
    margin-right: 10px;
    font-size: 20px;
    color: white;
`;

const MenuWrapper = styled.div`
    position: relative;
    float: left;
`;

const MenuItem = styled.div`
    position: relative;
    display: inline-block;
    color: white;
    cursor: pointer;
    padding: 5px;
`;

const MenuItemIcon = styled.div`
    display: inline-block;
    float: left;
    font-size: 20px;
    padding: 10px;
    transform: translateY( 3px );
    ${MenuItem}:hover & {
        color: red;
    }
`;

const MenuDropList = styled.div`
    display: none;
    position: absolute;
    left: 0;
    top: 100%;
    color: white;
    z-index: 10;
    width: 200px;
    ${MenuItem}:hover & {
        display: block;
    }
    background-color: rgba( 0, 0, 0, 0.5 );
    backdrop-filter: blur( 10px );
    border-radius: 0px 10px 10px 10px;
    overflow: hidden;
`;

const MenuDropListItem = styled.div`
    padding: 10px;
    margin: 0px;
    color: white;
    cursor: pointer;
    &:hover {
        background-color: #333;
    }
`;

const ShortcutLabel = styled.div`
    display: inline-block;
    float: right;
    margin-right: 5px;
    font-size: 11px;
    transform: translateY( 2px );
    color: grey;
`;

const ClearBoth = styled.div`
    clear: both;
`;

//

interface IMapModelAsset {
    id:                     string;
    name:                   string;
    buffer:                 number[];
    preview:                string;
    triangles:              number;
    textureId:              string;
};

interface IMapModelsAssetCategory {
    title:                  string;
    list:                   { [key:string]: IMapModelAsset };
    order:                  number;
};

interface IMapTextureAsset {
    id:                     string;
    name:                   string;
    buffer:                 number[];
    preview:                string;
};

export const Header = () => {

    const { map, saveMap, getModelById } = useContext( MapContext );
    const { open: OpenCreateMapPopup } = usePopupContext( 'CreateMap' );
    const { open: OpenLoadMapPopup } = usePopupContext( 'LoadMap' );

    //

    // map menu

    const createMapClickHandler = () : void => {

        OpenCreateMapPopup();

    };

    const loadMapClickHandler = () : void => {

        OpenLoadMapPopup();

    };

    const saveMapClickHandler = () : void => {

        saveMap();

    };

    const exportMapJSONClickHandler = () : void => {

        if ( ! map ) return;

        const assets: {
            models:             { [key:string]: IMapModelsAssetCategory };
            textures:           { [key:string]: IMapTextureAsset };
        } = {
            models: {},
            textures: {}
        };

        for ( const categoryName in map.assets.models ) {

            const category: {
                title:                  string;
                list:                   { [key:string]: IMapModelAsset };
                order:                  number;
            } = {
                title:      map.assets.models[ categoryName ].title,
                order:      map.assets.models[ categoryName ].order,
                list:       {}
            };

            for ( const item in map.assets.models[ categoryName ].list ) {

                category.list[ item ] = {
                    id:         map.assets.models[ categoryName ].list[ item ].id,
                    name:       map.assets.models[ categoryName ].list[ item ].name,
                    buffer:     Array.from( new Uint8Array( map.assets.models[ categoryName ].list[ item ].buffer ) ),
                    preview:    map.assets.models[ categoryName ].list[ item ].preview,
                    triangles:  map.assets.models[ categoryName ].list[ item ].triangles,
                    textureId:  map.assets.models[ categoryName ].list[ item ].textureId
                };

            }

            assets.models[ categoryName ] = category;

        }

        for ( const textureName in map.assets.textures ) {

            assets.textures[ textureName ] = {
                id:         map.assets.textures[ textureName ].id,
                name:       map.assets.textures[ textureName ].name,
                buffer:     Array.from( new Uint8Array( map.assets.textures[ textureName ].buffer ) ),
                preview:    map.assets.textures[ textureName ].preview
            };

        }

        const json = {
            id:             map.id,
            name:           map.name,
            params:         map.params,
            mapping: {
                width:      map.mapping.width,
                height:     map.mapping.height,
                data:       Array.from( map.mapping.data )
            },
            navGrid: {
                cellSize:   map.navGrid.cellSize,
                data:       Array.from( map.navGrid.data )
            },
            collisionGrid: {
                cellSize:   map.collisionGrid.cellSize,
                data:       Array.from( map.collisionGrid.data )
            },
            assets:         assets,
            lights:         map.lights,
            objects:        map.objects,
            createdAt:      map.createdAt.getTime()
        };

        const jsonString = JSON.stringify( json, null, 2 );
        const blob = new Blob([ jsonString ], { type: "application/json" });

        const a = document.createElement('a');
        a.href = URL.createObjectURL( blob );
        a.download = 'MapExport.json';
        document.body.appendChild( a );
        a.click();
        document.body.removeChild( a );
        URL.revokeObjectURL( a.href );

    };

    const exportMapBinClickHandler = () : void => {

        if ( ! map ) return;

        const infoBlockSize = 10;
        const objectsRawData: number[] = [];

        const models: { [key:string]: number } = {};
        let modelId = 0;

        map.objects.forEach( ( object ) => {

            const model = getModelById( object.modelId );
            if ( ! model ) return;

            if ( ! models[ model.name ] ) {

                models[ model.name ] = modelId ++;

            }

            objectsRawData.push( models[ model.name ] );

            for ( let i = 0; i < 16; i ++ ) {

                objectsRawData.push( object.matrix[ i ] );

            }

        });

        const mapping = map.mapping.data;
        const navMap = map.navGrid.data;
        const collisionMap = map.collisionGrid.data;

        const data = new ArrayBuffer( infoBlockSize * Uint32Array.BYTES_PER_ELEMENT + objectsRawData.length * Float32Array.BYTES_PER_ELEMENT + mapping.length * Uint8Array.BYTES_PER_ELEMENT + navMap.length * Uint8Array.BYTES_PER_ELEMENT + collisionMap.length * Uint8Array.BYTES_PER_ELEMENT );

        const infoBlock = new Uint32Array( data, 0, 10 );

        infoBlock[ 0 ] = infoBlockSize;
        infoBlock[ 1 ] = map.mapping.width;
        infoBlock[ 2 ] = map.mapping.height;
        infoBlock[ 3 ] = map.navGrid.cellSize;
        infoBlock[ 4 ] = map.collisionGrid.cellSize;

        const objectsBlock = new Int16Array( data, infoBlockSize * Uint32Array.BYTES_PER_ELEMENT, objectsRawData.length );

        for ( let i = 0; i < objectsRawData.length; i ++ ) {

            objectsBlock[ i ] = objectsRawData[ i ];

        }

        const mappingBlock = new Uint8Array( data, infoBlockSize * Uint32Array.BYTES_PER_ELEMENT + objectsRawData.length * Float32Array.BYTES_PER_ELEMENT, mapping.length );

        for ( let i = 0; i < mapping.length; i ++ ) {

            mappingBlock[ i ] = mapping[ i ];

        }

        const navMapBlock = new Uint8Array( data, infoBlockSize * Uint32Array.BYTES_PER_ELEMENT + objectsRawData.length * Float32Array.BYTES_PER_ELEMENT + mapping.length * Uint8Array.BYTES_PER_ELEMENT, navMap.length );

        for ( let i = 0; i < navMap.length; i ++ ) {

            navMapBlock[ i ] = navMap[ i ];

        }

        const collisionMapBlock = new Uint8Array( data, infoBlockSize * Uint32Array.BYTES_PER_ELEMENT + objectsRawData.length * Float32Array.BYTES_PER_ELEMENT + mapping.length * Uint8Array.BYTES_PER_ELEMENT + navMap.length * Uint8Array.BYTES_PER_ELEMENT, collisionMap.length );

        for ( let i = 0; i < collisionMap.length; i ++ ) {

            collisionMapBlock[ i ] = collisionMap[ i ];

        }

        //

        const blob = new Blob([ data ], { type: "application/json" });

        const a = document.createElement('a');
        a.href = URL.createObjectURL( blob );
        a.download = 'MapExport.bin';
        document.body.appendChild( a );
        a.click();
        document.body.removeChild( a );
        URL.revokeObjectURL( a.href );

    };

    // edit menu

    const undoClickHandler = () : void => {

        // EventManager.trigger( 'UI:UNDO' );

    };

    const redoClickHandler = () : void => {

        // EventManager.trigger( 'UI:REDO' );

    };

    // add menu

    const addClickHandler = ( objectType: string ) : void => {

    //     EventManager.trigger( 'UI>USER_EVENT:AddObject', {
    //         type:               'Mesh',
    //         name:               objectType + ' object',
    //         geometry:           { type: objectType },
    //         material:           { type: 'Lambert' },
    //         position:           { x: 0, y: 0, z: 0 },
    //         rotation:           { x: 0, y: 0, z: 0 },
    //         scale:              { x: 1, y: 1, z: 1 },
    //     });

    };

    if ( ! map ) {

        return null;

    }

    //

    return (
        <HeaderWrapper>
            <MapTitle>
                { map ? map.name : ( 'Empty map' ) }
            </MapTitle>
            <MenuWrapper>
                <MenuItem>
                    <MenuItemIcon>
                        <CiFileOn />
                    </MenuItemIcon>
                    <MenuDropList>
                        <MenuDropListItem onClick={ createMapClickHandler } >New<ShortcutLabel>cmd+n</ShortcutLabel></MenuDropListItem>
                        <MenuDropListItem onClick={ loadMapClickHandler } >Load</MenuDropListItem>
                        <MenuDropListItem onClick={ saveMapClickHandler } >Save<ShortcutLabel>cmd+s</ShortcutLabel></MenuDropListItem>
                        <MenuDropListItem onClick={ exportMapJSONClickHandler } >Export JSON<ShortcutLabel>cmd+e</ShortcutLabel></MenuDropListItem>
                        <MenuDropListItem onClick={ exportMapBinClickHandler } >Export BIN<ShortcutLabel/></MenuDropListItem>
                    </MenuDropList>
                </MenuItem>
                <MenuItem>
                    <MenuItemIcon>
                        <CiEdit />
                    </MenuItemIcon>
                    <MenuDropList>
                        <MenuDropListItem onClick={ undoClickHandler } >Undo<ShortcutLabel>cmd+z</ShortcutLabel></MenuDropListItem>
                        <MenuDropListItem onClick={ redoClickHandler } >Redo<ShortcutLabel>cmd+y</ShortcutLabel></MenuDropListItem>
                        <MenuDropListItem >Delete<ShortcutLabel>cmd+x</ShortcutLabel></MenuDropListItem>
                        <MenuDropListItem >Clone<ShortcutLabel>cmd+d</ShortcutLabel></MenuDropListItem>
                    </MenuDropList>
                </MenuItem>
                <MenuItem>
                    <MenuItemIcon>
                        <CiCirclePlus />
                    </MenuItemIcon>
                    <MenuDropList>
                        <MenuDropListItem>Group</MenuDropListItem>
                        <MenuDropListItem>Model</MenuDropListItem>
                        <MenuDropListItem onClick={ () => addClickHandler( 'Plane' ) } >Plane</MenuDropListItem>
                    </MenuDropList>
                </MenuItem>
                <ClearBoth />
            </MenuWrapper>
        </HeaderWrapper>
    );

};
