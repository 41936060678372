
import styled from "styled-components";
import { IoMdCloseCircleOutline } from "react-icons/io";
import { v4 as uuidv4 } from 'uuid';

import { PopupWrapper } from "./PopupWrapper";
import { useContext, useRef, useState } from "react";
import { useIndexedDB } from "../../hooks/IndexedDBHook";
import { IMap, MapContext } from "../../context/MapContext";

//

const PopupContainer = styled.div`
    width: 450px;
    height: 200px;
`;

const PopupTitle = styled.div`
    font-size: 24px;
    color: #000;
    text-align: center;
    font-weight: bold;
`;

const MapNameInput = styled.input`
    width: calc( 100% - 20px );
    padding: 10px;
    margin-top: 35px;
    margin-bottom: 5px;
    border-radius: 5px;
    border: 1px solid #ccc;
    font-size: 23px;
`;

const CloseBtn = styled(IoMdCloseCircleOutline)`
    position: absolute;
    top: 5px;
    right: 10px;
    font-size: 40px;
    cursor: pointer;
    color: #a55;
    transition: color 0.3s;
    &:hover {
        color: #f00;
    }
`;

const CreateBtn = styled.div`
    width: calc( 100% - 20px );
    padding: 15px 10px;
    background-color: #0a0;
    color: #fff;
    text-align: center;
    cursor: pointer;
    border-radius: 5px;
    margin-top: 10px;
    transition: background-color 0.3s;
    &:hover {
        background-color: #0f0;
    }
`;

//

export const CreateMapPopup = () => {

    const closePopupRef = useRef<() => void>(() => {});
    const { addDBItem } = useIndexedDB();
    const [ mapName, setMapName ] = useState<string>('');
    const { setMap } = useContext( MapContext );

    const CreateMapBtnClickHandler = async () => {

        try {

            const mappingData = new Float32Array( 7 * 512 * 512 );

            for ( let i = 0; i < mappingData.length; i += 7 ) {

                mappingData[ i + 0 ] = 127;
                mappingData[ i + 4 ] = 255;

            }

            const map: IMap = {
                id:                     uuidv4(),
                name:                   mapName,
                params: {
                    width:              100,
                    height:             100,
                    textureTileSize:    1,
                    cellSize:           10,
                    noiseFactor:        0,
                    noiseRepeat:        1
                },
                mapping: {
                    width:              512,
                    height:             512,
                    data:               mappingData
                },
                navGrid: {
                    cellSize:           25,
                    data:               new Float32Array()
                },
                collisionGrid: {
                    cellSize:           25,
                    data:               new Float32Array()
                },
                assets: {
                    models: {
                        buildings:      { title: 'Buildings', list: {}, order: 0 },
                        trees:          { title: 'Trees', list: {}, order: 1 },
                        deadTrees:      { title: 'Dead Trees', list: {}, order: 2 },
                        treesStumps:    { title: 'Trees Stumps', list: {}, order: 3 },
                        bushes:         { title: 'Bushes', list: {}, order: 4 },
                        grass:          { title: 'Grass', list: {}, order: 5 },
                        envElements:    { title: 'Bushes', list: {}, order: 6 },
                        units:          { title: 'Rocks', list: {}, order: 7 }
                    },
                    textures:           {}
                },
                objects:                [],
                units:                  [],
                lights: {
                    ambient:            { color: 0xffffff, intensity: 0.5 },
                    directional:        { color: 0xffffff, intensity: 0.5, direction: { x: 0.2, y: 0.2, z: 0.2 } }
                },
                createdAt:              new Date()
            };

            const navGridCellsW = Math.ceil( map.params.width / map.navGrid.cellSize );
            const navGridCellsH = Math.ceil( map.params.height / map.navGrid.cellSize );
            map.navGrid.data = new Float32Array( 4 * navGridCellsW * navGridCellsH ).fill( 0 );

            const collisionGridCellsW = Math.ceil( map.params.width / map.collisionGrid.cellSize );
            const collisionGridCellsH = Math.ceil( map.params.height / map.collisionGrid.cellSize );
            map.collisionGrid.data = new Float32Array( 4 * collisionGridCellsW * collisionGridCellsH ).fill( 0 );

            await addDBItem( 'Maps', map );

            closePopupRef.current();
            setMapName('');
            setMap( map );

        } catch ( e ) {

            console.error( e );

        }

    };

    const MapNameInputChangeHandler = ( e : any ) => {

        setMapName( e.target.value );

    };

    //

    return (
        <PopupWrapper name={'CreateMap'} closePopupRef={ closePopupRef } >
            <PopupContainer>
                <PopupTitle>Create map</PopupTitle>
                <CloseBtn onClick={ () => closePopupRef?.current() } />
                <MapNameInput placeholder="Map name" value={ mapName } onChange={ MapNameInputChangeHandler } />
                <CreateBtn onClick={ CreateMapBtnClickHandler }>Create</CreateBtn>
            </PopupContainer>
        </PopupWrapper>
    );

};
