
import { useContext, useEffect, useState } from "react";
import styled, { keyframes } from "styled-components";
import { Checkbox, FormControlLabel, Slider } from "@mui/material";

import { EditorContext } from "../../context/EditorContext";

//

const bounceIn = keyframes`
    0% { transform: scale(0.1); opacity: 0; }
    60% { transform: scale(1.2); opacity: 1; }
    100% { transform: scale(1); opacity: 1; }
`;

const bounceOut = keyframes`
    0% { transform: scale(1); opacity: 1; }
    40% { transform: scale(1.2); opacity: 1; }
    100% { transform: scale(0.1); opacity: 0; }
`;

interface PopupProps {
    $animateIn: boolean;
};

const PaintBrushMenuWrapper = styled.div<PopupProps>`
    position: absolute;
    bottom: 20px;
    left: 20px;
    z-index: 10;
    border-radius: 0px 10px 10px 10px;
    padding: 15px;
    width: 250px;
    height: 100px;
    background-color: rgba( 0, 0, 0, 0.5 );
    backdrop-filter: blur( 10px );
    animation: ${(props) => (props.$animateIn ? bounceIn : bounceOut )} 0.3s forwards;
`;

const SliderTitleLabel = styled.div`
    font-size: 10px;
    color: #fff;
    margin-bottom: -10px;
    text-align: left;
    width: 100%;
    letter-spacing: 0.03em;
`;

const MenuTitle = styled.div`
    position: absolute;
    top: -25px;
    left: 0px;
    padding: 5px 10px;
    font-size: 12px;
    color: #fff;
    margin-bottom: 10px;
    background-color: rgba( 0, 0, 0, 0.5 );
    backdrop-filter: blur( 10px );
    border-radius: 10px 10px 0px 0px;
`;

const WhiteCheckbox = styled(Checkbox)({
    color: 'white',
    '&.Mui-checked': {
      color: 'white',
    },
    '& .MuiSvgIcon-root': {
      fill: 'white',
    },
    '&.MuiButtonBase-root': {
        padding: '0px 10px'
    }
});

const WhiteFormControlLabel = styled(FormControlLabel)({
    color: 'white',
    fontSize: '10px', // Set the font size of the label text
    '& .MuiTypography-root': {
        letterSpacing: '0.03em',
        fontSize: '10px', // Ensure the label text has the desired font size
    }
});

//

export const ObjectPlacingMenu = () => {

    const editorParams = useContext( EditorContext );
    const [ animateIn, setAnimateIn ] = useState( false );
    const [ visible, setVisible ] = useState( false );

    useEffect( () => {

        if ( editorParams.placeMode ) {

            setVisible( true );
            setAnimateIn( true );

        } else {

            setAnimateIn( false );
            setTimeout(() => setVisible( false ), 600 );

        }

    }, [ editorParams.placeMode ] );

    const sizeSliderChangeHandler = ( event: any, value: number | number[] ) => {

        editorParams.setPlacingScale( value as number );

    };

    const strengthSliderChangeHandler = ( event: any, value: number | number[] ) => {

        editorParams.setPlacingRandomScale( value as number );

    };

    const rotationCheckboxChangeHandler = ( event: React.ChangeEvent<HTMLInputElement> ) => {

        editorParams.setPlacingRandomRotation( event.target.checked );

    };

    //

    if ( ! visible ) return null;

    return (
        <PaintBrushMenuWrapper $animateIn={ animateIn } >
            <MenuTitle>Object placing</MenuTitle>
            <SliderTitleLabel>Scale</SliderTitleLabel>
            <Slider value={ editorParams.placingScale } onChange={ sizeSliderChangeHandler } max={ 5 } step={ 0.01 } aria-label="Default" valueLabelDisplay="auto" />

            <SliderTitleLabel>Random scale</SliderTitleLabel>
            <Slider value={ editorParams.placingRandomScale } onChange={ strengthSliderChangeHandler } max={ 5 } step={ 0.01 } aria-label="Default" valueLabelDisplay="auto" />

            <WhiteFormControlLabel
                label="Random rotation"
                control={
                    <WhiteCheckbox checked={ editorParams.placingRandomRotation } size="medium" onChange={ rotationCheckboxChangeHandler } />
                }
            />
        </PaintBrushMenuWrapper>
    );

};
