
import { createContext, useState } from "react";
import { Vector3 } from "three";

//

interface EditorContextProps {
    navGridVisible:             boolean;
    setNavGridVisible:          ( visible: boolean ) => void;
    collisionGridVisible:       boolean;
    setCollisionGridVisible:    ( visible: boolean ) => void;
    wireframeVisible:           boolean;
    setWireframeVisible:        ( visible: boolean ) => void;
    mousePosition:              Vector3;
    setMousePosition:           ( position: Vector3 ) => void;
    mouseKeyDown:               { [ key: number ]: boolean };
    setMouseKeyDown:            ( value: { [ key: number ]: boolean } ) => void;
    keyboardKeyDown:            { [ key: number ]: boolean };
    setKeyboardKeyDown:         ( value: { [ key: number ]: boolean } ) => void;
    paintMode:                  number;
    setPaintMode:               ( mode: number ) => void;
    placeMode:                  string;
    setPlaceMode:               ( mode: string ) => void;

    placingScale:               number;
    setPlacingScale:            ( scale: number ) => void;
    placingRandomScale:         number;
    setPlacingRandomScale:      ( scale: number ) => void;
    placingRandomRotation:      boolean;
    setPlacingRandomRotation:   ( value: boolean ) => void;

    brushSize:                  number;
    setBrushSize:               ( size: number ) => void;
    brushStrength:              number;
    setBrushStrength:           ( strength: number ) => void;
    brushEdges:                 number;
    setBrushEdges:              ( edges: number ) => void;

    lightShading:               boolean;
    setLightShading:            ( value: boolean ) => void;
    normalMaps:                 boolean;
    setNormalMaps:              ( value: boolean ) => void;

    transformMode:              'translate' | 'rotate' | 'scale';
    setTransformMode:           ( mode: 'translate' | 'rotate' | 'scale' ) => void;

    selectMode:                 'point' | 'rect';
    setSelectMode:              ( mode: 'point' | 'rect' ) => void;
};

export const EditorContext = createContext<EditorContextProps>({
    collisionGridVisible: false,
    setCollisionGridVisible: () => null,
    navGridVisible: false,
    setNavGridVisible: () => null,
    wireframeVisible: false,
    setWireframeVisible: () => null,
    mousePosition: new Vector3(),
    setMousePosition: () => null,
    mouseKeyDown: {},
    setMouseKeyDown: () => null,
    keyboardKeyDown: {},
    setKeyboardKeyDown: () => null,
    paintMode: 0,
    setPaintMode: () => null,
    placeMode: '',
    setPlaceMode: () => null,

    placingScale: 1,
    setPlacingScale: () => null,
    placingRandomScale: 0.5,
    setPlacingRandomScale: () => null,
    placingRandomRotation: false,
    setPlacingRandomRotation: () => null,

    brushSize: 10,
    setBrushSize: () => null,
    brushStrength: 0.5,
    setBrushStrength: () => null,
    brushEdges: 0.5,
    setBrushEdges: () => null,

    lightShading: false,
    setLightShading: () => null,
    normalMaps: false,
    setNormalMaps: () => null,

    transformMode: 'translate',
    setTransformMode: () => null,

    selectMode: 'point',
    setSelectMode: () => null
});

export const EditorContextProvider = ( { children } : any ) => {

    const [ collisionGridVisible, setCollisionGridVisible ] = useState( false );
    const [ navGridVisible, setNavGridVisible ] = useState( false );
    const [ wireframeVisible, setWireframeVisible ] = useState( false );
    const [ mousePosition, setMousePosition ] = useState( new Vector3( -10000, -10000, 0 ) );
    const [ mouseKeyDown, setMouseKeyDown ] = useState<{ [ key: number ]: boolean }>({});
    const [ keyboardKeyDown, setKeyboardKeyDown ] = useState<{ [ key: number ]: boolean }>({});
    const [ paintMode, setPaintMode ] = useState( 0 );
    const [ placeMode, setPlaceMode ] = useState( '' );
    const [ placingScale, setPlacingScale ] = useState( 1 );
    const [ placingRandomScale, setPlacingRandomScale ] = useState( 0.5 );
    const [ placingRandomRotation, setPlacingRandomRotation ] = useState( false );
    const [ lightShading, setLightShading ] = useState( true );
    const [ normalMaps, setNormalMaps ] = useState( true );
    const [ brushSize, setBrushSize ] = useState( 10 );
    const [ brushStrength, setBrushStrength ] = useState( 0.5 );
    const [ brushEdges, setBrushEdges ] = useState( 0.5 );
    const [ transformMode, setTransformMode ] = useState<'translate' | 'rotate' | 'scale'>( 'translate' );
    const [ selectMode, setSelectMode ] = useState<'point' | 'rect'>( 'point' );

    //

    return (
        <EditorContext.Provider value={{
            collisionGridVisible, setCollisionGridVisible,
            navGridVisible, setNavGridVisible,
            wireframeVisible, setWireframeVisible,
            mousePosition, setMousePosition,
            mouseKeyDown, setMouseKeyDown,
            keyboardKeyDown, setKeyboardKeyDown,
            paintMode, setPaintMode,
            placeMode, setPlaceMode,
            placingScale, setPlacingScale,
            placingRandomScale, setPlacingRandomScale,
            placingRandomRotation, setPlacingRandomRotation,
            lightShading, setLightShading,
            normalMaps, setNormalMaps,
            brushSize, setBrushSize,
            brushStrength, setBrushStrength,
            brushEdges, setBrushEdges,
            transformMode, setTransformMode,
            selectMode, setSelectMode
        }}>
            { children }
        </EditorContext.Provider>
    );

};
