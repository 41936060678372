
import { ToastContainer } from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';

import { MapContextProvider } from "../../context/MapContext";
import { PopupContextProvider } from "../../context/PopupsContext";
import { EditorContextProvider } from "../../context/EditorContext";

import { IndexedDBProvider } from "../../hooks/IndexedDBHook";
import { Header } from "../Header";
import { RightPanel } from "../RightPanel";
import { CreateMapPopup } from "../Popups/CreateMapPopup";
import { ImportMapPopup } from "../Popups/ImportMapPopup";
import { Renderport } from "../Renderport";
import { RightPanelContextProvider } from "../../context/RightPanelContext";
import { LoadMapPopup } from "../Popups/LoadMapPopup";
import { AssetManagerProvider } from "../../hooks/AssetManagerHook";

//

export const App = () => {

    return (
        <IndexedDBProvider>
            <EditorContextProvider>
                <AssetManagerProvider>
                    <RightPanelContextProvider>
                        <MapContextProvider>
                            <PopupContextProvider>
                                <Header />
                                <RightPanel />
                                <Renderport />

                                <CreateMapPopup />
                                <ImportMapPopup />
                                <LoadMapPopup />
                                <ToastContainer />
                            </PopupContextProvider>
                        </MapContextProvider>
                    </RightPanelContextProvider>
                </AssetManagerProvider>
            </EditorContextProvider>
        </IndexedDBProvider>
    );

};
