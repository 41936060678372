
import { useFrame } from '@react-three/fiber';
import { useRef } from 'react';
import { Mesh } from 'three';

//

export const WelcomeScene = () => {

    const cubeRef = useRef<Mesh>( null );

    useFrame( () => {

        if ( ! cubeRef.current ) return;

        cubeRef.current.rotation.x += 0.01;
        cubeRef.current.rotation.y += 0.01;

    });

    //

    return (
        <mesh ref={ cubeRef } >
            <boxGeometry attach="geometry" args={[2, 2, 2]} />
            <meshBasicMaterial attach="material" transparent={ true } wireframe />
        </mesh>
    );

};
