
import { useContext, useEffect, useRef } from "react";
import { CompressedArrayTexture, CompressedTexture, DoubleSide, Float32BufferAttribute, LineSegments, Mesh, MeshPhongMaterial, Object3D } from "three";

import { MapContext } from "../../context/MapContext";
import { TerrainShaderMaterial } from "./materials/Terrain.Material";
import { EditorContext } from "../../context/EditorContext";
import { getPixelValue } from "../../utils/Math.util";
import { useAssetManager } from "../../hooks/AssetManagerHook";

//

export const TerrainGfx = () => {

    const { map, mappingCanvas, mappingNeedsUpdate, setMappingNeedsUpdate, getModelById } = useContext( MapContext );
    const { wireframeVisible } = useContext( EditorContext );
    const lines = useRef<LineSegments>( null );
    const picker = useRef<Mesh>( null );
    const AssetManager = useAssetManager();
    const editorParams = useContext( EditorContext );
    const prePlacingRef = useRef<Object3D>( null );
    const previousPlacingMode = useRef<string>( '' );

    //

    useEffect( () => {

        if ( ! lines.current || ! map ) return;

        const vertices = [];
        const width = map.params.width;
        const height = map.params.height;

        const divisionsX = Math.floor( width / map.params.cellSize );
        const divisionsY = Math.floor( height / map.params.cellSize );

        for ( let i = 0; i <= divisionsX; i ++ ) {

            for ( let j = 0; j <= divisionsY; j ++ ) {

                if ( j !== divisionsY ) {

                    vertices.push( i * map.params.cellSize - width / 2, 0, j * map.params.cellSize - height / 2 );
                    vertices.push( i * map.params.cellSize - width / 2, 0, j * map.params.cellSize - height / 2 + map.params.cellSize );

                }

                if ( i !== divisionsX ) {

                    vertices.push( i * map.params.cellSize - width / 2, 0, j * map.params.cellSize - height / 2 );
                    vertices.push( i * map.params.cellSize - width / 2 + map.params.cellSize, 0, j * map.params.cellSize - height / 2 );

                }

            }

        }

        lines.current.geometry.setAttribute( 'position', new Float32BufferAttribute( vertices, 3 ) );

        lines.current.userData.origPos = null;

        setMappingNeedsUpdate( true );

    }, [ map?.params.width, map?.params.height, map?.params.cellSize ] );

    useEffect( () => {

        if ( ! mappingNeedsUpdate ) return;
        if ( ! lines.current ) return;
        if ( ! picker.current ) return;
        if ( ! map ) return;

        // update helper grid lines

        const posArr = lines.current.geometry.attributes.position.array;
        if ( ! lines.current.userData.origPos ) lines.current.userData.origPos = new Float32Array( posArr );
        const ctx = mappingCanvas.getContext( '2d' );
        const data = ctx?.getImageData( 0, 0, 3 * map.mapping.width, map.mapping.height );
        if ( ! data ) return;
        const origPosArr = lines.current.userData.origPos;

        for ( let i = 0; i < posArr.length; i += 3 ) {

            const x = ( 0.005 + 0.99 * ( origPosArr[ i + 0 ] + 0.5 * map.params.width ) / map.params.width ) * ( map.mapping.width - 1 );
            const y = ( 0.005 + 0.99 * ( origPosArr[ i + 2 ] + 0.5 * map.params.height ) / map.params.height ) * ( map.mapping.height - 1 );

            const elevation = getPixelValue( data, mappingCanvas.width, mappingCanvas.height, x, y );
            const n = getPixelValue( data, mappingCanvas.width, mappingCanvas.height, x + 2 * map.mapping.width, y );

            posArr[ i + 0 ] = origPosArr[ i + 0 ] + ( n[0] / 255 * 2 - 1 ) / 3;
            posArr[ i + 1 ] = ( elevation[0] / 255 - 0.5 ) * 170 + ( n[2] / 255 * 2 - 1 ) / 8;
            posArr[ i + 2 ] = origPosArr[ i + 2 ] + ( n[1] / 255 * 2 - 1 ) / 3;

        }

        lines.current.geometry.attributes.position.needsUpdate = true;

        // update picker mesh

        const pickerPosArr = picker.current.geometry.attributes.position.array;

        for ( let i = 0; i < pickerPosArr.length; i += 3 ) {

            const x = ( 0.005 + 0.99 * ( pickerPosArr[ i + 0 ] + 0.5 * map.params.width ) / map.params.width ) * ( map.mapping.width - 1 );
            const y = ( 0.005 + 0.99 * ( 0.5 * map.params.height - pickerPosArr[ i + 1 ] ) / map.params.height ) * ( map.mapping.height - 1 );
            const elevation = getPixelValue( data, mappingCanvas.width, mappingCanvas.height, x, y );
            pickerPosArr[ i + 2 ] = ( elevation[0] / 255 - 0.5 ) * 170;

        }

        picker.current.geometry.attributes.position.needsUpdate = true;

        //

        setMappingNeedsUpdate( false );

    }, [ mappingNeedsUpdate ] );

    useEffect( () => {

        if ( ! map ) return;
        if ( ! prePlacingRef.current ) return;

        if ( previousPlacingMode.current !== editorParams.placeMode ) {

            prePlacingRef.current.children = [];

            if ( editorParams.placeMode !== '' ) {

                const prePlacingMesh = AssetManager.models[ editorParams.placeMode ].clone();
                const textureId = getModelById( editorParams.placeMode )?.textureId;

                prePlacingMesh.traverse( ( child ) => {

                    if ( child instanceof Mesh ) {

                        child.material = new MeshPhongMaterial({ color: 0xffffff, transparent: true, opacity: 0.8, alphaTest: 0.5, side: DoubleSide });
                        if ( textureId ) child.material.map = AssetManager.textures[ textureId ];

                    }

                });

                prePlacingRef.current.add( prePlacingMesh );

            }

            previousPlacingMode.current = editorParams.placeMode;

        }

        prePlacingRef.current.position.set( ( editorParams.mousePosition.x - 0.5 ) * map.params.width, editorParams.mousePosition.z, - ( editorParams.mousePosition.y - 0.5 ) * map.params.height );

    }, [ prePlacingRef.current, editorParams.mousePosition, editorParams.placeMode ] );

    useEffect( () => {

        const placeObject = () => {

            if ( ! prePlacingRef.current ) return;

            const object = prePlacingRef.current;

            object.scale.setScalar( editorParams.placingScale + Math.random() * editorParams.placingRandomScale );

            if ( editorParams.placingRandomRotation ) {

                object.rotation.y = Math.random() * Math.PI * 2;

            }

        };

        placeObject();

        document.querySelector( 'canvas' )!.addEventListener( 'mouseup', placeObject );

        return () => {

            document.querySelector( 'canvas' )!.removeEventListener( 'mouseup', placeObject );

        };

    }, [ editorParams.placingScale, editorParams.placingRandomScale, editorParams.placingRandomRotation ] );

    //

    if ( ! map ) return null;

    return (
        <>
            <object3D ref={ prePlacingRef } name="pre-placing-object" />
            <mesh rotation={[ - Math.PI / 2, 0, 0 ]} >
                <planeGeometry attach="geometry" args={[ map.params.width, map.params.height, Math.floor( map.params.width / map.params.cellSize ), Math.floor( map.params.height / map.params.cellSize ) ]} />
                { AssetManager.ready && <TerrainShaderMaterial
                    diffuseAtlas={ AssetManager.textures['terrainDiffuseAtlas'] as CompressedArrayTexture }
                    normalAtlas={ AssetManager.textures['terrainNormalAtlas'] as CompressedArrayTexture }
                    noise={ AssetManager.textures['noise'] as CompressedTexture }
                /> }
            </mesh>
            <mesh ref={ picker } rotation={[ - Math.PI / 2, 0, 0 ]} name="TerrainPicker" >
                <planeGeometry attach="geometry" args={[ map.params.width, map.params.height, Math.floor( map.params.width / map.params.cellSize ), Math.floor( map.params.height / map.params.cellSize ) ]} />
                <meshBasicMaterial attach="material" color={ 0x000000 } visible={ false } />
            </mesh>
            <lineSegments ref={ lines } position={[ 0, 0, 0 ]} visible={ wireframeVisible } >
                <bufferGeometry attach="geometry" />
                <lineBasicMaterial />
            </lineSegments>
        </>
    );

};
