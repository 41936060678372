
import styled from "styled-components";
import { IoMdCloseCircleOutline } from "react-icons/io";

import { PopupWrapper } from "../../Popups/PopupWrapper";
import { useContext, useRef, useState } from "react";
import { IMap, IMapModelsAssetCategory, MapContext } from "../../../context/MapContext";

//

const PopupContainer = styled.div`
    width: 450px;
    height: 200px;
`;

const PopupTitle = styled.div`
    font-size: 24px;
    color: #000;
    text-align: center;
    font-weight: bold;
`;

const MapNameInput = styled.input`
    width: calc( 100% - 20px );
    padding: 10px;
    margin-top: 35px;
    margin-bottom: 5px;
    border-radius: 5px;
    border: 1px solid #ccc;
    font-size: 23px;
`;

const CloseBtn = styled(IoMdCloseCircleOutline)`
    position: absolute;
    top: 5px;
    right: 10px;
    font-size: 40px;
    cursor: pointer;
    color: #a55;
    transition: color 0.3s;
    &:hover {
        color: #f00;
    }
`;

const CreateBtn = styled.div`
    width: calc( 100% - 20px );
    padding: 15px 10px;
    background-color: #0a0;
    color: #fff;
    text-align: center;
    cursor: pointer;
    border-radius: 5px;
    margin-top: 10px;
    transition: background-color 0.3s;
    &:hover {
        background-color: #0f0;
    }
`;

//

export const CreateNewAssetCategoryPopup = () => {

    const closePopupRef = useRef<() => void>(() => {});
    const [ category, setCategory ] = useState<string>( '' );
    const { setMap } = useContext( MapContext );

    const CreateAssetCategoryBtnClickHandler = async () => {

        if ( category === '' ) return;

        setMap( ( prev: IMap | null ) => {

            if ( ! prev ) return prev;

            const categoryName = category.trim().replace( /\s+/g, '_' ).toLowerCase();
            const newCategory: IMapModelsAssetCategory = { title: category, list: {}, order: Object.keys( prev.assets ).length };
            const newMap: IMap = { ...prev, assets: { ...prev.assets, models: { ...prev.assets.models, [categoryName]: newCategory } } };
            return newMap;

        } );

        closePopupRef.current();
        setCategory( '' );

    };

    const CategoryNameInputChangeHandler = ( e : any ) => {

        setCategory( e.target.value );

    };

    //

    return (
        <PopupWrapper name={'CreateNewAssetCategory'} closePopupRef={ closePopupRef } >
            <PopupContainer>
                <PopupTitle>Create new asset category</PopupTitle>
                <CloseBtn onClick={ () => closePopupRef?.current() } />
                <MapNameInput placeholder="Category name" value={ category } onChange={ CategoryNameInputChangeHandler } />
                <CreateBtn onClick={ CreateAssetCategoryBtnClickHandler }>Create</CreateBtn>
            </PopupContainer>
        </PopupWrapper>
    );

};
