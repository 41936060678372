
import { OrbitControls } from '@react-three/drei';
import { useThree } from '@react-three/fiber';
import { useContext, useEffect, useRef } from 'react';
import { BoxGeometry, BoxHelper, InstancedMesh, Matrix4, Mesh, MOUSE, Object3D, Quaternion, Raycaster, Vector2, Vector3 } from 'three';
import { TransformControls, TransformControlsMode } from 'three/examples/jsm/controls/TransformControls';

import { TerrainGfx } from './TerrainGfx';
import { ObjectsGfx } from './ObjectsGfx';
import { EditorContext } from '../../context/EditorContext';
import { IMap, MapContext } from '../../context/MapContext';

//

export const WorldGfx = () => {

    const { gl, camera, scene } = useThree();
    const controlsRef = useRef<any>( null );
    const raycaster = useRef<Raycaster>( new Raycaster() );
    const mouse = useRef<Vector2>( new Vector2() );
    const transformControlsRef = useRef<TransformControls | null>( null );
    const selectBoxRef = useRef<Object3D[]>( [] );
    const selectedObjectsRef = useRef<{ pool: InstancedMesh, objectInstanceId: number }[]>( [] );
    const EditorParams = useContext( EditorContext );
    const mousePosRef = useRef<Vector2>( new Vector2() );
    const mouseClickPosRef = useRef<Vector2>( new Vector2() );
    const { map, setMap } = useContext( MapContext );
    const selectorDivRef = useRef<HTMLDivElement | null>( null );
    const selectionModeRef = useRef<string>( EditorParams.selectMode );

    //

    const onTransformControlsDragChange = ( event: any ) => {

        controlsRef.current.enabled = ! transformControlsRef.current?.dragging;
        if ( selectionModeRef.current === 'rect' ) controlsRef.current.enabled = false;

    };

    const updateSelection = ( pool: InstancedMesh, objectInstanceId: number, deselect: boolean ) => {

        window.oncontextmenu = ( event: MouseEvent ) => event.preventDefault();

        const wrapperDummyObject = new Object3D();

        // check if object already selected

        if ( selectBoxRef.current.find( ( selectionObject: Object3D ) => ( selectionObject.userData.objectInstanceId === objectInstanceId && selectionObject.userData.pool === pool ) ) ) {

            if ( deselect ) {

                selectBoxRef.current = selectBoxRef.current.filter( ( selectionObject: Object3D ) => {

                    const match = ( selectionObject.userData.objectInstanceId === objectInstanceId && selectionObject.userData.pool === pool );

                    if ( match ) {

                        scene.remove( selectionObject );

                    }

                    return ! match;

                });

            }

        } else {

            // prepare selection object

            const box = pool.userData.box;
            const selectionObject = new BoxHelper( new Mesh( new BoxGeometry( box.max.x - box.min.x, box.max.y - box.min.y, box.max.z - box.min.z ) ), 0xffff00 );
            selectBoxRef.current.push( selectionObject );
            pool.getMatrixAt( objectInstanceId, selectionObject.matrix );
            selectionObject.matrix.decompose( selectionObject.position, selectionObject.quaternion, selectionObject.scale );
            selectionObject.position.y += ( box.max.y - box.min.y ) / 2;
            selectionObject.updateMatrix();
            scene.add( selectionObject );

            // prepare dummy object

            const dummyObject = new Object3D();

            // compute particular object dummy object position

            pool.getMatrixAt( objectInstanceId, dummyObject.matrix );
            dummyObject.matrix.decompose( dummyObject.position, dummyObject.quaternion, dummyObject.scale );

            //

            selectionObject.userData.dummyObject = dummyObject;
            selectionObject.userData.pool = pool;
            selectionObject.userData.objectInstanceId = objectInstanceId;
            selectionObject.userData.id = pool.userData.objects[ objectInstanceId ];

        }

        // compute wrapper dummy object position

        const avgPosition = new Vector3();

        selectBoxRef.current.forEach( ( selectionObject: Object3D ) => {

            avgPosition.add( selectionObject.position );

        });

        avgPosition.divideScalar( selectBoxRef.current.length );
        wrapperDummyObject.position.copy( avgPosition );
        wrapperDummyObject.updateMatrix();
        scene.add( wrapperDummyObject );

        selectBoxRef.current.forEach( ( selectionObject: Object3D ) => {

            const dummyObject = selectionObject.userData.dummyObject as Object3D;
            const pool = selectionObject.userData.pool as InstancedMesh;
            const objectInstanceId = selectionObject.userData.objectInstanceId as number;

            pool.getMatrixAt( objectInstanceId, dummyObject.matrix );
            dummyObject.matrix.decompose( dummyObject.position, dummyObject.quaternion, dummyObject.scale );

            dummyObject.position.sub( avgPosition );

        });

        // prepare transform controls

        if ( ! transformControlsRef.current ) {

            transformControlsRef.current = new TransformControls( camera, gl.domElement );

            transformControlsRef.current.addEventListener( 'objectChange', updateObject );
            transformControlsRef.current.addEventListener( 'dragging-changed', onTransformControlsDragChange );

            scene.add( transformControlsRef.current );

        }

        transformControlsRef.current.setMode( EditorParams.transformMode as TransformControlsMode );
        transformControlsRef.current.setSize( 0.5 );
        transformControlsRef.current.visible = true;
        transformControlsRef.current.userData.wrapperDummyObject = wrapperDummyObject;

        transformControlsRef.current.attach( wrapperDummyObject );

        selectBoxRef.current.forEach( ( selectionObject: Object3D ) => {

            wrapperDummyObject.add( selectionObject.userData.dummyObject );

        });

    };

    const deselectObject = () => {

        if ( ! transformControlsRef.current ) return;
        if ( selectionModeRef.current !== 'point' ) return;

        const object = transformControlsRef.current.userData.wrapperDummyObject as Object3D;
        transformControlsRef.current.detach();
        transformControlsRef.current.visible = false;

        scene.remove( object );

        selectBoxRef.current.forEach( ( selectionObject: Object3D ) => {

            scene.remove( selectionObject );

        });

        selectBoxRef.current = [];

    };

    const updateObject = () => {

        selectBoxRef.current.forEach( ( selectionObject: Object3D ) => {

            const boxHelper = selectionObject as BoxHelper;
            const dummyObject = boxHelper.userData.dummyObject as Object3D;
            const pool = boxHelper.userData.pool as InstancedMesh;
            const objectInstanceId = boxHelper.userData.objectInstanceId as number;

            dummyObject.updateWorldMatrix( true, true );
            dummyObject.updateMatrix();

            const position = dummyObject.getWorldPosition( new Vector3() );
            const quaternion = dummyObject.getWorldQuaternion( new Quaternion() );
            const scale = dummyObject.getWorldScale( new Vector3() );

            pool.setMatrixAt( objectInstanceId, dummyObject.matrixWorld );
            pool.instanceMatrix.needsUpdate = true;

            const box = pool.userData.box;

            boxHelper.position.set( position.x, position.y + ( box.max.y - box.min.y ) / 2, position.z );
            boxHelper.quaternion.copy( quaternion );
            boxHelper.scale.copy( scale );
            boxHelper.updateMatrix();
            boxHelper.update();

            setMap( ( prev: IMap | null ) => {

                if ( ! prev ) return prev;

                return {
                    ...prev,
                    objects: prev.objects.map( ( object ) => {

                        if ( object.id === selectionObject.userData.id ) {

                            return {
                                ...object,
                                matrix: dummyObject.matrixWorld.toArray()
                            };

                        }

                        return object;

                    })
                };

            });

        });

    };

    const removeObject = ( poolObject: InstancedMesh, id: string, instanceId: number ) => {

        const matrix = new Matrix4();
        matrix.setPosition( new Vector3( -100000, 0, 0 ) );

        poolObject.setMatrixAt( instanceId, matrix );
        poolObject.instanceMatrix.needsUpdate = true;

        setMap( ( prev: IMap | null ) => {

            if ( ! prev ) return prev;

            return {
                ...prev,
                objects: prev.objects.filter( ( object ) => object.id !== id )
            };

        });

    };

    const duplicateObjects = () => {

        const newObjects: { id: string, matrix: number[], modelId: string, textureId: string }[] = [];

        selectBoxRef.current.forEach( ( selectedObject ) => {

            const pool = selectedObject.userData.pool;
            const objectInstanceId = selectedObject.userData.objectInstanceId;

            const matrix = new Matrix4();
            pool.getMatrixAt( objectInstanceId, matrix );

            pool.count ++;
            pool.setMatrixAt( pool.count - 1, matrix );
            pool.instanceMatrix.needsUpdate = true;

            newObjects.push({
                id:             pool.userData.objects[ objectInstanceId ],
                modelId:        pool.userData.modelId,
                textureId:      pool.userData.textureId,
                matrix:         matrix.toArray()
            });

        });

        setMap( ( prev: IMap | null ) => {

            if ( ! prev ) return prev;

            return {
                ...prev,
                objects: [
                    ...prev.objects,
                    ...newObjects
                ]
            };

        });

    };

    useEffect( () => {

        controlsRef.current.mouseButtons = {
            LEFT: MOUSE.PAN,
            MIDDLE: MOUSE.DOLLY,
            RIGHT: MOUSE.ROTATE
        };

        camera.far = 50000;
        camera.updateProjectionMatrix();
        camera.position.set( 0, 100, 100 );
        camera.lookAt( 0, 0, 0 );

    }, [] );

    useEffect( () => {

        const viewport = document.querySelector('#main-viewport') as HTMLCanvasElement;

        const mouseDownHandler = ( event: MouseEvent ) => {

            if ( transformControlsRef.current?.dragging ) return;

            mousePosRef.current.set( event.clientX, event.clientY );
            mouseClickPosRef.current.set( event.clientX, event.clientY );

            if ( selectionModeRef.current === 'rect' ) {

                selectorDivRef.current = document.createElement( 'div' );
                selectorDivRef.current.style.display = 'none';
                selectorDivRef.current.style.position = 'absolute';
                selectorDivRef.current.style.border = '1px solid #000';
                selectorDivRef.current.style.pointerEvents = 'none';
                selectorDivRef.current.style.zIndex = '1000';
                selectorDivRef.current.style.backgroundColor = 'rgba( 255, 255, 255, 0.1 )';
                document.body.appendChild( selectorDivRef.current );

            }

            //

            EditorParams.setMouseKeyDown({ ...EditorParams.setMouseKeyDown, [ event.button ]: true });

            if ( EditorParams.paintMode ) {

                controlsRef.current.panSpeed = 0;

            }

        };

        const mouseUpHandler = ( event: MouseEvent ) => {

            if ( mouseClickPosRef.current.distanceTo( new Vector2( event.clientX, event.clientY ) ) < 5 ) {

                if ( event.button === 2 ) {

                    EditorParams.setPaintMode( 0 );
                    EditorParams.setPlaceMode( '' );
                    deselectObject();
                    selectedObjectsRef.current = [];
                    controlsRef.current.panSpeed = 1;
                    return;

                }

                if ( EditorParams.paintMode || EditorParams.placeMode ) return;

                deselectObject();

                //

                raycaster.current.setFromCamera( mouse.current, camera );
                const assetsSelected = raycaster.current.intersectObjects( scene.getObjectByName('asset-objects')!.children, true );

                if ( assetsSelected.length ) {

                    const pool = ( assetsSelected[0].object as InstancedMesh );
                    const objectInstanceId = assetsSelected[0].instanceId!;

                    updateSelection( pool, objectInstanceId, true );

                }

            }

            if ( selectorDivRef.current ) {

                selectorDivRef.current.style.display = 'none';
                selectorDivRef.current.remove();
                selectorDivRef.current = null;

            }

            //

            EditorParams.setMouseKeyDown({ ...EditorParams.setMouseKeyDown, [ event.button ]: false });

            if ( event.button === 0 ) {

                controlsRef.current.panSpeed = 1;

            }

        };

        const mouseMoveHandler = ( event: MouseEvent ) => {

            if ( selectorDivRef.current && mouseClickPosRef.current.distanceTo( new Vector2( event.clientX, event.clientY ) ) > 5 ) {

                const rectX = Math.min( mouseClickPosRef.current.x, event.clientX );
                const rectY = Math.min( mouseClickPosRef.current.y, event.clientY );
                const rectWidth = Math.abs( mouseClickPosRef.current.x - event.clientX );
                const rectHeight = Math.abs( mouseClickPosRef.current.y - event.clientY );

                const selectorDom = selectorDivRef.current as HTMLDivElement;
                selectorDom.style.display = 'block';
                selectorDom.style.left = rectX + 'px';
                selectorDom.style.top = rectY + 'px';
                selectorDom.style.width = rectWidth + 'px';
                selectorDom.style.height = rectHeight + 'px';

                const newSelectedObjects: { pool: InstancedMesh, objectInstanceId: number }[] = [];
                const pools = scene.getObjectByName('asset-objects')!.children as InstancedMesh[];
                const matrix = new Matrix4();
                const position = new Vector3();
                const vector = new Vector3();

                pools.forEach( ( pool ) => {

                    for ( let i = 0; i < pool.count; i ++ ) {

                        pool.getMatrixAt( i, matrix ); // Get instance's transformation matrix
                        position.set( 0, 0, 0 ).applyMatrix4( matrix ); // Transform origin by the matrix
                        position.applyMatrix4( pool.matrixWorld ); // Apply the world transformation
                        vector.copy( position ).project( camera );

                        const screenX = (vector.x * 0.5 + 0.5) * window.innerWidth;
                        const screenY = (vector.y * -0.5 + 0.5) * window.innerHeight;

                        if ( screenX >= rectX && screenX <= rectX + rectWidth && screenY >= rectY && screenY <= rectY + rectHeight ) {

                            let alreadySelected = false;

                            for ( let j = 0; j < selectedObjectsRef.current.length; j ++ ) {

                                if ( selectedObjectsRef.current[ j ].pool === pool && selectedObjectsRef.current[ j ].objectInstanceId === i ) {

                                    alreadySelected = true;
                                    break;

                                }

                            }

                            if ( ! alreadySelected ) {

                                newSelectedObjects.push({ pool, objectInstanceId: i });
                                selectedObjectsRef.current.push({ pool, objectInstanceId: i });
                                updateSelection( pool, i, false );

                            }

                        }

                    }

                });

            }

            mousePosRef.current.set( event.clientX, event.clientY );

            mouse.current.set(
                ( event.clientX / gl.domElement.clientWidth ) * 2 - 1,
                - ( event.clientY / gl.domElement.clientHeight ) * 2 + 1
            );

            if ( ! EditorParams.paintMode && ! EditorParams.placeMode ) return;

            raycaster.current.setFromCamera( mouse.current, camera );

            const intersects = raycaster.current.intersectObject( scene.getObjectByName('TerrainPicker')! );

            if ( intersects.length > 0 ) {

                EditorParams.setMousePosition( new Vector3( intersects[ 0 ].uv?.x, intersects[ 0 ].uv?.y, intersects[ 0 ].point.y ) );

            } else {

                EditorParams.setMousePosition( new Vector3( -10000, -10000, 0 ) );

            }

        };

        const keyDownHandler = ( event: KeyboardEvent ) => {

            event.preventDefault();

            EditorParams.setKeyboardKeyDown({ ...EditorParams.keyboardKeyDown, [ event.code ]: true });

            if ( event.code === 'ShiftLeft' ) {

                EditorParams.setSelectMode( 'rect' );
                controlsRef.current.enabled = false;

            }

            if ( ! transformControlsRef.current ) return;

            //

            if ( event.code === 'KeyD' && event.metaKey ) {

                duplicateObjects();

            }

            if ( event.code === 'Escape' ) {

                deselectObject();

            }

            if ( event.code === 'KeyX' ) {

                selectBoxRef.current.forEach( ( selectionObject: Object3D ) => {

                    removeObject( selectionObject.userData.pool, selectionObject.userData.id, selectionObject.userData.objectInstanceId );

                });

                deselectObject();

            }

            if ( event.code === 'KeyR' ) {

                EditorParams.setTransformMode( 'rotate' );

            }

            if ( event.code === 'KeyT' ) {

                EditorParams.setTransformMode( 'translate' );

            }

            if ( event.code === 'KeyS' ) {

                EditorParams.setTransformMode( 'scale' );

            }

        };

        const keyUpHandler = ( event: KeyboardEvent ) => {

            EditorParams.setKeyboardKeyDown({ ...EditorParams.keyboardKeyDown, [ event.code ]: false });

            if ( event.code === 'ShiftLeft' ) {

                EditorParams.setSelectMode( 'point' );
                controlsRef.current.enabled = true;

            }

        };

        viewport.addEventListener( 'mousemove', mouseMoveHandler );
        viewport.addEventListener( 'mousedown', mouseDownHandler );
        viewport.addEventListener( 'mouseup', mouseUpHandler );
        document.addEventListener( 'keydown', keyDownHandler );
        document.addEventListener( 'keyup', keyUpHandler );

        //

        return () => {

            viewport.removeEventListener( 'mousemove', mouseMoveHandler );
            viewport.removeEventListener( 'mousedown', mouseDownHandler );
            viewport.removeEventListener( 'mouseup', mouseUpHandler );
            document.removeEventListener( 'keydown', keyDownHandler );
            document.removeEventListener( 'keyup', keyUpHandler );

        };

    }, [ EditorParams.paintMode, EditorParams.placeMode, EditorParams.transformMode ] );

    useEffect( () => {

        if ( ! transformControlsRef.current ) return;

        transformControlsRef.current.setMode( EditorParams.transformMode as TransformControlsMode );

    }, [ EditorParams.transformMode ] );

    useEffect( () => {

        selectionModeRef.current = EditorParams.selectMode;

    }, [ EditorParams.selectMode ] );

    //

    if ( ! map ) return null;

    return (
        <>
            <TerrainGfx />
            <ObjectsGfx />
            <OrbitControls args={[ camera, gl.domElement ]} ref={ controlsRef } zoomSpeed={ 0.3 } />
            <directionalLight position={[ map!.lights.directional.direction.x, map!.lights.directional.direction.y, map!.lights.directional.direction.z ]} intensity={ map!.lights.directional.intensity } />
            <ambientLight intensity={ map!.lights.ambient.intensity } />
        </>
    );

};
