
import { useContext, useEffect, useState } from "react";
import styled, { keyframes } from "styled-components";
import { Slider } from "@mui/material";

import { EditorContext } from "../../context/EditorContext";

//

const bounceIn = keyframes`
    0% { transform: scale(0.1); opacity: 0; }
    60% { transform: scale(1.2); opacity: 1; }
    100% { transform: scale(1); opacity: 1; }
`;

const bounceOut = keyframes`
    0% { transform: scale(1); opacity: 1; }
    40% { transform: scale(1.2); opacity: 1; }
    100% { transform: scale(0.1); opacity: 0; }
`;

interface PopupProps {
    $animateIn: boolean;
};

const PaintBrushMenuWrapper = styled.div<PopupProps>`
    position: absolute;
    bottom: 20px;
    left: 20px;
    z-index: 10;
    border-radius: 0px 10px 10px 10px;
    padding: 15px;
    width: 250px;
    height: 100px;
    background-color: rgba( 0, 0, 0, 0.5 );
    backdrop-filter: blur( 10px );
    animation: ${(props) => (props.$animateIn ? bounceIn : bounceOut )} 0.3s forwards;
`;

const SliderTitleLabel = styled.div`
    font-size: 10px;
    color: #fff;
    margin-bottom: -10px;
    text-align: left;
    width: 100%;
`;

const MenuTitle = styled.div`
    position: absolute;
    top: -25px;
    left: 0px;
    padding: 5px 10px;
    font-size: 12px;
    color: #fff;
    margin-bottom: 10px;
    background-color: rgba( 0, 0, 0, 0.5 );
    backdrop-filter: blur( 10px );
    border-radius: 10px 10px 0px 0px;
`;

//

export const PaintBrushMenu = () => {

    const editorParams = useContext( EditorContext );
    const [ animateIn, setAnimateIn ] = useState( false );
    const [ visible, setVisible ] = useState( false );

    useEffect( () => {

        if ( editorParams.paintMode ) {

            setVisible( true );
            setAnimateIn( true );

        } else {

            setAnimateIn( false );
            setTimeout(() => setVisible( false ), 600 );

        }

    }, [ editorParams.paintMode ] );

    const sizeSliderChangeHandler = ( event: any, value: number | number[] ) => {

        editorParams.setBrushSize( value as number );

    };

    const strengthSliderChangeHandler = ( event: any, value: number | number[] ) => {

        editorParams.setBrushStrength( value as number );

    };

    const edgesSliderChangeHandler = ( event: any, value: number | number[] ) => {

        editorParams.setBrushEdges( value as number );

    };

    //

    if ( ! visible ) return null;

    return (
        <PaintBrushMenuWrapper $animateIn={ animateIn } >
            <MenuTitle>Paint brush</MenuTitle>
            <SliderTitleLabel>Size</SliderTitleLabel>
            <Slider value={ editorParams.brushSize } onChange={ sizeSliderChangeHandler } max={ 50 } aria-label="Default" valueLabelDisplay="auto" />

            {
                editorParams.paintMode >= 100 ? null : (
                    <>
                        <SliderTitleLabel>Strength</SliderTitleLabel>
                        <Slider value={ editorParams.brushStrength } onChange={ strengthSliderChangeHandler } max={ 1 } step={ 0.01 } aria-label="Default" valueLabelDisplay="auto" />

                        <SliderTitleLabel>Gradient</SliderTitleLabel>
                        <Slider value={ editorParams.brushEdges } onChange={ edgesSliderChangeHandler } max={ 1 } step={ 0.01 } aria-label="Default" valueLabelDisplay="auto" />
                    </>
                )
            }
        </PaintBrushMenuWrapper>
    );

};
