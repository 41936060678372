
import styled from 'styled-components';
import { useContext, useEffect, useState } from 'react';
import { Vector3 } from 'three';
import { TbHexagon3D } from "react-icons/tb";
import { MdTerrain } from "react-icons/md";
import { AiOutlineGlobal } from "react-icons/ai";

import { usePopupContext } from '../../context/PopupsContext';
import { MapContext } from '../../context/MapContext';
import { RightPanelContext } from '../../context/RightPanelContext';
import { GeneralTabContent } from './General-tab/GeneralTabContent';
import { AssetsTabContent } from './Assets-tab/AssetsTabContent';
import { TerrainTabContent } from './Terrain-tab/TerrainTabContent';
import { EditorContext } from '../../context/EditorContext';
import { InputWithApply } from '../Other/InputWithApply';

//

export const PropItem = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 10px;
`;

export const PropItemLabel = styled.div`
    padding-left: 20px;
    font-size: 15px;
    color: #fff;
    width: 200px;
`;

export const PropItemInput = styled(InputWithApply)`
    width: calc( 100% - 120px );
`;

export const PropItemInputHalfWidth = styled(InputWithApply)`
    width: calc( 50% - 60px );
`;

export const PropItemInputOneThirdWidth = styled(InputWithApply)`
    width: calc( 30% - 26px );
`;

//

const LeftPanelWrapper = styled.div`
    position: absolute;
    top: 20px;
    bottom: 20px;
    right: 20px;
    width: 450px;
    background-color: rgba( 0, 0, 0, 0.5 );
    border-radius: 10px;
    z-index: 2;
`;

const WelcomeWrapper = styled.div`
    margin-top: 40%;
    width: 60%;
    margin-left: auto;
    margin-right: auto;
    text-transform: uppercase;
`;

const Button = styled.div`
    position: relative;
    display: block;
    padding: 10px;
    color: white;
    cursor: pointer;
    padding: 10px;
    margin-top: 5px;
    text-align: center;
    background-color: rgba( 255, 30, 30, 0.5 );
    margin-bottom: 15px;
    transition: background-color 0.3s;
    &:hover {
        background-color: rgba( 255, 30, 30, 0.7 );
    }
`;

const TabWrapper = styled.div`
    position: absolute;
    top: 50px;
    left: -35px;
`;

interface TabBtnProps {
    $active: boolean;
};

const TabBtn = styled.div<TabBtnProps>`
    padding: 10px;
    margin-bottom: 10px;
    color: white;
    cursor: pointer;
    border-radius: 10px 0px 0px 10px;
    background-color: rgba( 255, 255, 255, 0.05 );
    transition: background-color 0.3s;
    ${({ $active }) => !$active && `
        &:hover {
            background-color: rgba(255, 255, 255, 0.1);
        }
    `}
    ${({ $active }) => $active && `
        background-color: rgba( 255, 0, 0, 0.5 );
    `}
`;

const Background = styled.div`
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    backdrop-filter: blur(10px);
    border-radius: 10px;
    z-index: -1;
`;

//

export const RightPanel = () => {

    const { map, saveMap } = useContext( MapContext );
    const { activeTab, setActiveTab } = useContext( RightPanelContext );
    const [ keyPressed, setKeyPressed ] = useState<{ [key:string]: boolean }>({});
    const editorParams = useContext( EditorContext );

    const { open: OpenCreateMapPopup } = usePopupContext( 'CreateMap' );
    const { open: OpenImportMapPopup } = usePopupContext( 'ImportMap' );
    const { open: OpenLoadMapPopup } = usePopupContext( 'LoadMap' );

    const TabBtnClickHandler = ( tab: string ) => {

        setActiveTab( tab );

        editorParams.setPaintMode( 0 );
        editorParams.setMousePosition( new Vector3( -100000, -100000, 0 ) );

    };

    useEffect( () => {

        const onKeydown = ( event: KeyboardEvent ) => {

            setKeyPressed( ( prevState ) => ( { ...prevState, [ event.key ]: true } ) );

            if ( ( event.ctrlKey || event.metaKey ) && event.key === 's' ) {

                event.preventDefault();
                saveMap();

            }

        };

        const onKeyup = ( event: KeyboardEvent ) => {

            setKeyPressed( ( prevState ) => ( { ...prevState, [ event.key ]: false } ) );

        };

        window.addEventListener( 'keydown', onKeydown );
        window.addEventListener( 'keyup', onKeyup );

        return () => {

            window.removeEventListener( 'keydown', onKeydown );
            window.removeEventListener( 'keyup', onKeyup );

        };

    }, [ map ] );

    //

    return (
        <LeftPanelWrapper>
            {
                map ? (
                    <>
                        <TabWrapper>
                            <TabBtn $active={ activeTab === 'general' } onClick={ () => TabBtnClickHandler('general') }><AiOutlineGlobal /></TabBtn>
                            <TabBtn $active={ activeTab === 'terrain' } onClick={ () => TabBtnClickHandler('terrain') }><MdTerrain /></TabBtn>
                            <TabBtn $active={ activeTab === 'objects' } onClick={ () => TabBtnClickHandler('objects') }><TbHexagon3D /></TabBtn>
                        </TabWrapper>
                        <GeneralTabContent $active={ activeTab === 'general' } />
                        <TerrainTabContent $active={ activeTab === 'terrain' } />
                        <AssetsTabContent $active={ activeTab === 'objects' } />
                    </>
                ) : (
                    <WelcomeWrapper>
                        <Button onClick={ OpenCreateMapPopup }>Create Map</Button>
                        <Button onClick={ OpenImportMapPopup } >Import Map</Button>
                        <Button onClick={ OpenLoadMapPopup } >Load Map</Button>
                    </WelcomeWrapper>
                )
            }
            <Background />
        </LeftPanelWrapper>
    );

};
