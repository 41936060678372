
import { useCallback, useEffect, useState } from "react";
import { usePopupContext } from "../../context/PopupsContext";
import styled, { keyframes } from "styled-components";

//

const ANIMATION_TIME = 400;

const bounceIn = keyframes`
    0% { transform: scale(0.1); opacity: 0; }
    60% { transform: scale(1.2); opacity: 1; }
    100% { transform: scale(1); opacity: 1; }
`;

const bounceOut = keyframes`
    0% { transform: scale(1); opacity: 1; }
    40% { transform: scale(1.2); opacity: 1; }
    100% { transform: scale(0.1); opacity: 0; }
`;

const opacityBounceIn = keyframes`
    0% { opacity: 0; }
    100% { opacity: 1; }
`;

const opacityBounceOut = keyframes`
    0% { opacity: 1; }
    100% { opacity: 0; }
`;

const PopupContainer = styled.div`
    position: fixed;
    display: flex;
    justify-content: center;
    align-items: center;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    z-index: 1000;
`;

interface PopupProps {
    $animateIn: boolean;
};

const Popup = styled.div<PopupProps>`
    background: white;
    padding: 20px;
    border-radius: 8px;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
    z-index: 2000;
    animation: ${(props) => (props.$animateIn ? bounceIn : bounceOut)} ${ ANIMATION_TIME }ms forwards;
`;

const PopupBackground = styled.div<PopupProps>`
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 99;
    background-color: rgba(0,0,0,0.7);
    animation: ${(props) => (props.$animateIn ? opacityBounceIn : opacityBounceOut )} ${ ANIMATION_TIME }ms forwards;
    z-index: 1900;
`;

//

export const PopupWrapper = ( { children, name, closePopupRef, onClose } : { children: any, name: string, closePopupRef?: any, onClose?: () => void } ) => {

    const { state, close } = usePopupContext( name );

    const [ visible, setVisible ] = useState( false );
    const [ animateIn, setAnimateIn ] = useState( false );
    const [ opened, setOpened ] = useState( false );

    //

    const closeWithAnimation = () => {

        if ( ! opened ) return;

        setAnimateIn( false );
        setTimeout( () => {

            setVisible( false );
            setOpened( false );
            if ( onClose ) onClose();
            close();

        }, ANIMATION_TIME );

    };

    const keypressHandler = ( e: KeyboardEvent ) => {

        if ( e.key === 'Escape' ) {

            closeWithAnimation();

        }

    };

    //

    useEffect( () => {

        if ( state ) {

            document.addEventListener( 'keydown', keypressHandler );

            if ( ! visible ) {

                setVisible( true );
                setAnimateIn( true );
                setTimeout( () => setOpened( true ), ANIMATION_TIME );

            } else {

                setOpened( true );

            }

        } else {

            closeWithAnimation();

        }

        return () => {

            document.removeEventListener( 'keydown', keypressHandler );

        };

    }, [ state, opened ] );

    useEffect( () => {

        if ( ! closePopupRef ) return;

        const closePopup = () => {

            closeWithAnimation();

        };

        closePopupRef.current = closePopup;

    }, [ closePopupRef, opened ] );

    //

    if ( ! visible ) return null;

    return (
        <>
            <PopupContainer>
                <Popup $animateIn={ animateIn } >
                    { children }
                </Popup>
                <PopupBackground $animateIn={ animateIn } onClick={ closeWithAnimation } />
            </PopupContainer>
        </>
    );

};
