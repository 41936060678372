
import { useContext, useEffect, useRef, useState } from "react";
import { Box3, BufferGeometry, DoubleSide, DynamicDrawUsage, InstancedMesh, Matrix4, Mesh, MeshPhongMaterial, Object3D } from "three";
import { v4 as uuidv4 } from 'uuid';

import { EditorContext } from "../../context/EditorContext";
import { MapContext } from "../../context/MapContext";
import { useAssetManager } from "../../hooks/AssetManagerHook";
import { useThree } from "@react-three/fiber";

//

export const ObjectsGfx = () => {

    const { scene } = useThree();
    const objects = useRef<Object3D>( null );
    const editorParams = useContext( EditorContext );
    const AssetManager = useAssetManager();
    const { setMap, map, getModelById } = useContext( MapContext );
    const objectsPool = useRef<{ [key: string]: InstancedMesh }>( {} );
    const [ currentMapName, setCurrentMapName ] = useState<string>( '' );

    //

    const preparePool = ( modelId: string ) : InstancedMesh | null => {

        if ( ! objects.current ) return null;

        const model = AssetManager.models[ modelId ];
        const modelParams = getModelById( modelId );

        if ( ! model ) {

            console.error( 'Model not found' );
            return null;

        }

        if ( model.children.length > 1 ) {

            console.error( 'Model has more than one child' );
            return null;

        }

        const sourceGeometry = ( model.children[ 0 ] as Mesh ).geometry;

        const objectMaterial = new MeshPhongMaterial( { color: 0xffffff, side: DoubleSide, transparent: true, alphaTest: 0.5 } );
        if ( modelParams?.textureId ) objectMaterial.map = AssetManager.textures[ modelParams.textureId ];

        const objectGeometry = new BufferGeometry();

        objectGeometry.setIndex( sourceGeometry.getIndex()?.clone()! );
        objectGeometry.setAttribute( 'position', sourceGeometry.getAttribute( 'position' )?.clone()! );
        objectGeometry.setAttribute( 'normal', sourceGeometry.getAttribute( 'normal' )?.clone()! );
        objectGeometry.setAttribute( 'uv', sourceGeometry.getAttribute( 'uv' )?.clone()! );

        const pool = new InstancedMesh( objectGeometry, objectMaterial, 1000 );
        pool.instanceMatrix.setUsage( DynamicDrawUsage );

        objects.current.add( pool );
        pool.count = 0;
        pool.userData.box = new Box3().setFromObject( model.children[ 0 ] );
        pool.userData.objects = [];

        objectsPool.current[ modelId ] = pool;

        return pool;

    };

    const placeObject = ( event: MouseEvent ) => {

        if ( event.button === 2 ) {

            return;

        }

        if ( ! objects.current ) return;
        if ( ! map ) return

        const id = uuidv4();
        const pool = objectsPool.current[ editorParams.placeMode ] ?? preparePool( editorParams.placeMode );

        const preObject = scene.getObjectByName( 'pre-placing-object' )!;

        pool.setMatrixAt( pool.count, preObject.matrix );
        pool.userData.objects[ pool.count ] = id;
        pool.count ++;
        pool.instanceMatrix.needsUpdate = true;

        pool.computeBoundingSphere();

        setMap({
            ...map,
            objects: [
                ...map.objects,
                {
                    id:         id,
                    modelId:    editorParams.placeMode,
                    matrix:     preObject.matrix.toArray(),
                    textureId:  getModelById( editorParams.placeMode )?.textureId!
                }
            ]
        });

    };

    useEffect( () => {

        if ( ! editorParams.placeMode ) return;

        document.querySelector( 'canvas' )!.addEventListener( 'mousedown', placeObject );

        return () => {

            document.querySelector( 'canvas' )!.removeEventListener( 'mousedown', placeObject );

        };

    }, [ editorParams.placeMode, editorParams.mousePosition ] );

    useEffect( () => {

        if ( ! map ) return;
        if ( ! objects.current ) return;
        if ( currentMapName === map.name ) return;

        setCurrentMapName( map.name );

        objects.current.children = [];

        for ( const object of map.objects ) {

            const pool = objectsPool.current[ object.modelId ] ?? preparePool( object.modelId );

            const matrix = new Matrix4().fromArray( object.matrix );
            pool.setMatrixAt( pool.count, matrix );
            pool.userData.objects[ pool.count ] = object.id;
            pool.count ++;
            pool.instanceMatrix.needsUpdate = true;

        }

    }, [ map ] );

    //

    return (
        <object3D name="asset-objects" ref={ objects } />
    );

};
