
import { createContext, useContext, useEffect, useState, useCallback } from 'react';
import { openDB, IDBPDatabase } from 'idb';

//

type StoresType = 'General' | 'Assets' | 'Maps';

const IndexedDBContext = createContext({
    addDBItem: async ( storeName: StoresType, item: any ) => {},
    getDBItems: async ( storeName: StoresType ) : Promise<any[]> => [],
    getDBItem: async ( storeName: StoresType, id: string ) : Promise<any> => {},
    updateDBItem: async ( storeName: StoresType, id: string, updates: any ) => {},
    deleteDBItem: async ( storeName: StoresType, id: string ) => {}
});

export const useIndexedDB = () => {

    return useContext( IndexedDBContext );

};

export const IndexedDBProvider = ( { children }: any ) => {

    const [ db, setDb ] = useState<IDBPDatabase | null>( null );

    useEffect( () => {

        const initDB = async () => {

            const db = await openDB('AtlasGenerator', 1, {
                upgrade ( db ) {

                    if ( ! db.objectStoreNames.contains('General') ) {

                        db.createObjectStore( 'General', { keyPath: 'name' } );

                    }

                    if ( ! db.objectStoreNames.contains('Assets') ) {

                        db.createObjectStore( 'Assets', { keyPath: 'name' } );

                    }

                    if ( ! db.objectStoreNames.contains('Maps') ) {

                        db.createObjectStore( 'Maps', { keyPath: 'id' } );

                    }

                }
            });

            setDb( db );

        };

        initDB();

    }, [] );

    const addDBItem = useCallback( async ( storeName: StoresType, item: any ) => {

        if ( ! db ) return;

        const tx = db.transaction( storeName, 'readwrite' );
        const store = tx.objectStore( storeName );

        try {

            const existingItem = await store.get( item.name );

            if ( ! existingItem ) {

                await store.add({ ...item });

            } else {

                // nothing to do

            }

            await tx.done;

        } catch ( error ) {

            console.error (`Error adding item to ${ storeName }`, error );

        }

    }, [ db ] );

    const getDBItems = useCallback( async ( storeName: StoresType ) : Promise<any[]> => {

        if ( ! db ) return [];

        const tx = db.transaction( storeName, 'readonly' );
        const store = tx.objectStore( storeName );
        const items = await store.getAll();
        await tx.done;
        return items;

    }, [ db ] );

    const getDBItem = useCallback( async ( storeName: StoresType, id: string ) => {

        if ( ! db ) return;

        const tx = db.transaction( storeName, 'readonly' );
        const store = tx.objectStore( storeName );
        const item = await store.get( id );
        await tx.done;
        return item;

    }, [ db ] );

    const updateDBItem = useCallback( async ( storeName: StoresType, id: string, updates: any ) => {

        if ( ! db ) return;

        const tx = db.transaction( storeName, 'readwrite' );
        const store = tx.objectStore( storeName );
        const item = await store.get( id );
        const updatedItem = { ...item, ...updates };
        await store.put( updatedItem );
        await tx.done;

    }, [ db ] );

    const deleteDBItem = useCallback( async ( storeName: StoresType, id: string ) => {

        if ( ! db ) return;

        const tx = db.transaction( storeName, 'readwrite' );
        const store = tx.objectStore( storeName );
        await store.delete( id );
        await tx.done;

    }, [ db ] );

    //

    return (
        <IndexedDBContext.Provider value={{ addDBItem, getDBItems, getDBItem, updateDBItem, deleteDBItem }}>
            { children }
        </IndexedDBContext.Provider>
    );

};
