
import { AmbientLight, Box3, DoubleSide, Mesh, MeshPhongMaterial, PerspectiveCamera, Scene, SpotLight, WebGLRenderer } from "three";
import { useEffect, useState } from "react";
import { GLTFLoader } from 'three/examples/jsm/loaders/GLTFLoader.js';
import { DRACOLoader } from 'three/examples/jsm/loaders/DRACOLoader.js';
import styled from "styled-components";
import { IMapModelAsset } from "../../context/MapContext";
import { useAssetManager } from "../../hooks/AssetManagerHook";

//

const TitleInput = styled.input`
    position: absolute;
    top: 5px;
    left: 5px;
    width: calc( 100% - 100px );
    border-radius: 5px;
    border: 1px solid #ccc;
    font-size: 14px;
    padding: 2px;
`;

const TrisCount = styled.div`
    position: absolute;
    bottom: 5px;
    right: 10px;
    color: #aaa;
    text-align: right;
    color: #aaa;
    font-size: 11px;
`;

//

export const ModelPreviewImage = ( { name, model, width, height }: { name: string, model: IMapModelAsset, width: number, height: number } ) => {

    const AssetManager = useAssetManager();
    const [ previewImage, setPreviewImage ] = useState<string>( '' );
    const [ title, setTitle ] = useState<string>( name.replace( '.glb', '' ).replace( /[\-\_]/g, ' ' ) );

    const OFFSET_Y = 0.6; // percent of the bounding box size offset
    const ZOOM = 0.8;

    //

    useEffect( () => {

        const scene = new Scene();
        const camera = new PerspectiveCamera( 75, 1, 0.1, 1000 );
        const ambient = new AmbientLight( 0xffffff, 0.5 );
        scene.add( ambient );
        const spot = new SpotLight( 0xffffff, 2, 1000 );
        scene.add( spot );

        const dracoLoader = new DRACOLoader();
        dracoLoader.setDecoderPath('/libs/draco/');

        const loader = new GLTFLoader();
        loader.setDRACOLoader( dracoLoader );

        const texture = AssetManager.textures[ model.textureId ];

        //

        loader.parse( model.buffer, '', ( gltf ) => {

            const renderer = new WebGLRenderer({ antialias: true });
            renderer.setClearColor( 0x000000, 0 );

            scene.add( gltf.scene );

            scene.traverse( ( child ) => {

                if ( child instanceof Mesh ) {

                    child.material = new MeshPhongMaterial( { color: 0xffffff, map: texture, transparent: true, alphaTest: 0.8, side: DoubleSide } );

                }

            });

            const boundingBox = new Box3();
            boundingBox.setFromObject( gltf.scene );
            const boundingBoxRadius = Math.max( boundingBox.max.x - boundingBox.min.x, boundingBox.max.y - boundingBox.min.y, boundingBox.max.z - boundingBox.min.z );
            camera.position.set( boundingBoxRadius, boundingBoxRadius * ( 1 + OFFSET_Y ), boundingBoxRadius ).multiplyScalar( ZOOM );
            camera.lookAt( 0, boundingBoxRadius * OFFSET_Y, 0 );

            spot.position.set( boundingBoxRadius * 1.5, boundingBoxRadius, boundingBoxRadius ).multiplyScalar( ZOOM );
            spot.target.position.set( 0, 0, 0 );

            renderer.setSize( width, height );
            renderer.render( scene, camera );

            const imageDataURL = renderer.domElement.toDataURL();
            setPreviewImage( imageDataURL );

            model.preview = imageDataURL;
            model.triangles = renderer.info.render.triangles;

            renderer.dispose();
            renderer.forceContextLoss();

            dracoLoader.dispose();

        });

    }, [ model ] );

    const titleOnChangeHandler = ( event: React.ChangeEvent<HTMLInputElement> ) => {

        setTitle( event.target.value );

    };

    //

    return (
        <div className="asset-preview-image">
            <img src={ previewImage } alt="Asset preview" />
            <TitleInput placeholder="Title" value={ title } onChange={ titleOnChangeHandler } />
            <TrisCount>{ model.triangles } tris</TrisCount>
        </div>
    );

};
