
import styled from "styled-components";
import { useContext, useRef, useState } from "react";
import { IoMdCloseCircleOutline } from "react-icons/io";
import { v4 as uuidv4 } from 'uuid';

import { PopupWrapper } from "./PopupWrapper";
import { MapContext } from "../../context/MapContext";

//

const PopupContainer = styled.div`
    width: 450px;
`;

const PopupTitle = styled.div`
    font-size: 24px;
    color: #000;
    text-align: center;
    font-weight: bold;
`;

const CloseBtn = styled(IoMdCloseCircleOutline)`
    position: absolute;
    top: 5px;
    right: 10px;
    font-size: 40px;
    cursor: pointer;
    color: #a55;
    transition: color 0.3s;
    &:hover {
        color: #f00;
    }
`;

const MapNameInput = styled.input`
    width: calc( 100% - 20px );
    padding: 10px;
    margin-top: 35px;
    margin-bottom: 5px;
    border-radius: 5px;
    border: 1px solid #ccc;
    font-size: 23px;
`;

const HiddenFileInput = styled.input`
    display: none;
`;

const FileSelectLabel = styled.label`
    width: calc(100% - 20px);
    padding: 15px 10px;
    background-color: #ccc;
    color: #fff;
    text-align: center;
    cursor: pointer;
    border-radius: 5px;
    border: 1px solid #aaa;
    margin-top: 10px;
    transition: background-color 0.3s;
    display: inline-block;

    &:hover {
        background-color: #0f0;
    }
`;

const ImportBtn = styled.div`
    width: calc( 100% - 20px );
    padding: 15px 10px;
    background-color: #0a0;
    color: #fff;
    text-align: center;
    cursor: pointer;
    border-radius: 5px;
    margin-top: 10px;
    transition: background-color 0.3s;
    &:hover {
        background-color: #0f0;
    }
`;

//

export const ImportMapPopup = () => {

    const closePopupRef = useRef<() => void>( () => {} );
    const [ mapName, setMapName ] = useState<string>( '' );
    const [ file, setFile ] = useState<File | null>( null );
    const { loadMap } = useContext( MapContext );

    //

    const MapNameInputChangeHandler = ( event: React.ChangeEvent<HTMLInputElement> ) => {

        setMapName( event.target.value );

    };

    const FileInputChangeHandler = ( event: React.ChangeEvent<HTMLInputElement> ) => {

        if ( event.target.files && event.target.files.length > 0 ) {

            setFile( event.target.files[0] );

        }

    };

    const ImportMapBtnClickHandler = () => {

        const reader = new FileReader();

        reader.onload = ( e ) => {

            const data = e.target?.result as string;
            let mapJson: any;

            try {

                mapJson = JSON.parse( data );

                mapJson.id = uuidv4();
                mapJson.name = mapName;

                for ( const categoryName in mapJson.assets.models ) {

                    for ( const modelId in mapJson.assets.models[ categoryName ].list ) {

                        mapJson.assets.models[ categoryName ].list[ modelId ].buffer = new Uint8Array( mapJson.assets.models[ categoryName ].list[ modelId ].buffer ).buffer;

                    }

                }

                for ( const textureName in mapJson.assets.textures ) {

                    mapJson.assets.textures[ textureName ].buffer = new Uint8Array( mapJson.assets.textures[ textureName ].buffer ).buffer;

                }

            } catch ( error ) {

                console.error("Error parsing JSON file:", error);

            }

            loadMap( mapJson );
            closePopupRef.current();

        };

        reader.readAsText( file as Blob );

    };

    //

    return (
        <PopupWrapper name={'ImportMap'} closePopupRef={ closePopupRef } >
            <PopupContainer>
                <PopupTitle>Import map</PopupTitle>
                <CloseBtn onClick={ () => closePopupRef?.current() } />
                <MapNameInput placeholder="Map name" value={ mapName } onChange={ MapNameInputChangeHandler } />
                <FileSelectLabel htmlFor="file-input">
                    { file ? file.name : 'Select Map File' }
                </FileSelectLabel>
                <HiddenFileInput
                    id="file-input"
                    type="file"
                    accept=".json"
                    onChange={ FileInputChangeHandler }
                />
                <ImportBtn onClick={ ImportMapBtnClickHandler }>Import</ImportBtn>
            </PopupContainer>
        </PopupWrapper>
    );

};
