
import { CompressedArrayTexture, GLSL3, Mesh, OrthographicCamera, PlaneGeometry, Scene, ShaderMaterial, Texture, TextureLoader, WebGLRenderer } from 'three';
import { useEffect, useState } from "react";
import { useAssetManager } from "../../hooks/AssetManagerHook";
import { KTX2Loader } from 'three/examples/jsm/loaders/KTX2Loader';
import { cloneArrayBuffer } from '../../utils/Math.util';

//

export const TexturePreviewImage = ( { textureId, width, height, buffer, name, savePreview }: { textureId?: number, width: number, height: number, buffer?: ArrayBuffer, name?: string, savePreview?: ( preview: string ) => void } ) => {

    const AssetManager = useAssetManager();
    const [ renderedImageBase64, setRenderedImageBase64 ] = useState<string | null>( null );

    //

    const renderImage = ( texture: Texture | CompressedArrayTexture ) => {

        const renderer = new WebGLRenderer();
        renderer.setSize( width, height );

        const scene = new Scene();
        const camera = new OrthographicCamera( -1, 1, 1, -1, 0.5, 10 );
        camera.position.set( 0, 0, 1 );

        const plane = new Mesh( new PlaneGeometry( 2, 2 ), new ShaderMaterial({
            defines: {
                IS_ARRAY_TEXTURE: ( texture instanceof CompressedArrayTexture ) ? 1 : 0
            },
            uniforms: {
                map:            { value: texture },
                textureId:      { value: textureId }
            },
            glslVersion: GLSL3,
            vertexShader: /* glsl */`
                out vec2 vUv;

                void main () {

                    vUv = uv;

                    vec4 mvPosition = modelMatrix * vec4( position, 1.0 );
                    mvPosition = viewMatrix * mvPosition;

                    gl_Position = projectionMatrix * mvPosition;

                }
            `,
            fragmentShader: /* glsl */`
                in vec2 vUv;

                #if ( IS_ARRAY_TEXTURE == 1 )

                    uniform sampler2DArray map;

                #else

                    uniform sampler2D map;

                #endif

                uniform int textureId;

                layout(location = 0) out lowp vec4 pc_fragColor;

                void main () {

                    #if ( IS_ARRAY_TEXTURE == 1 )

                        pc_fragColor = texture( map, vec3( vUv, textureId ) );

                    #else

                        pc_fragColor = texture( map, vUv );

                    #endif

                }
            `
        }) );
        scene.add( plane );

        renderer.render( scene, camera );

        const imageDataURL = renderer.domElement.toDataURL();
        setRenderedImageBase64( imageDataURL );

        if ( savePreview ) savePreview( imageDataURL );

        renderer.dispose();
        renderer.forceContextLoss();

    };

    useEffect( () => {

        if ( ! AssetManager.ready ) return;

        let texture: CompressedArrayTexture | Texture | null = null;

        if ( buffer && name ) {

            const bufferCopy = cloneArrayBuffer( buffer );

            if ( name.indexOf( 'ktx2' ) !== -1 ) {

                const ktx2Loader = new KTX2Loader();
                const renderer = new WebGLRenderer();
                ktx2Loader.setTranscoderPath( 'libs/basis/' ).detectSupport( renderer );

                // @ts-ignore
                ktx2Loader.parse( bufferCopy, ( texture ) => {

                    renderImage( texture );
                    renderer.forceContextLoss();
                    renderer.dispose();

                }, ( error: any ) => {

                    console.error( error );

                });

            } else {

                const blob = new Blob( [ bufferCopy ], { type: 'image/' + name.split('.') } );
                const url = URL.createObjectURL( blob );

                const textureLoader = new TextureLoader();
                textureLoader.load( url, ( texture ) => {

                    renderImage( texture );

                });

            }

            return;

        }

        //

        texture = AssetManager.textures[ 'terrainDiffuseAtlas' ];
        renderImage( texture );


    }, [ AssetManager.ready, buffer ] );

    //

    return (
        <img src={ renderedImageBase64 ?? '' } />
    )

};
