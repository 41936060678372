
import styled from "styled-components";
import { IoMdCloseCircleOutline } from "react-icons/io";
import { MdDeleteOutline } from "react-icons/md";
import { v4 as uuidv4 } from 'uuid';

import { PopupWrapper } from "../../Popups/PopupWrapper";
import { useContext, useEffect, useRef, useState } from "react";
import { IMap, IMapModelAsset, MapContext } from "../../../context/MapContext";
import { ModelPreviewImage } from "../../Other/ModelPreviewImage";
import { TextureSelectDropdown } from "./TextureSelectDropdown";
import { useAssetManager } from "../../../hooks/AssetManagerHook";

//

const PopupContainer = styled.div`
    width: 60vw;
    height: 60vh;
`;

const PopupTitle = styled.div`
    font-size: 24px;
    color: #000;
    text-align: left;
    font-weight: bold;
`;

const CloseBtn = styled(IoMdCloseCircleOutline)`
    position: absolute;
    top: 5px;
    right: 10px;
    font-size: 40px;
    cursor: pointer;
    color: #a55;
    transition: color 0.3s;
    &:hover {
        color: #f00;
    }
`;

const CreateBtn = styled.div`
    position: absolute;
    bottom: 10px;
    left: 0px;
    width: calc( 100% - 20px );
    padding: 15px 10px;
    background-color: #0a0;
    color: #fff;
    text-align: center;
    cursor: pointer;
    border-radius: 5px;
    margin-top: 10px;
    transition: background-color 0.3s;
    &:hover {
        background-color: #0f0;
    }
`;

interface AssetDropZoneProps {
    $dragOver: boolean;
    $active: boolean;
};

const AssetDropZone = styled.div<AssetDropZoneProps>`
    position: absolute;
    display: ${ ( { $dragOver } ) => $dragOver ? "block" : "none" };
    visibility: ${ ( { $active } ) => $active ? "visible" : "hidden" };
    top: 55px;
    left: 20px;
    right: 20px;
    bottom: 65px;
    border: ${ ( { $dragOver } ) => $dragOver ? "3px dashed #5f5" : "3px dashed #ccc" };
    background-color: ${ ( { $dragOver } ) => $dragOver ? "rgba( 200, 255, 200, 0.4 )" : "rgba( 255, 255, 255, 0 )" };
    border-radius: 5px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 20px;
    color: #ccc;
    cursor: pointer;
    transition: color 0.3s;
    &:hover {
        color: #000;
    }
`;

const AssetDropZoneTitle = styled.div`
    position: absolute;
    top: 50%;
    width: 100%;
    text-align: center;
    font-size: 25px;
    color: #aaa;
`;

const AssetList = styled.div`
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(230px, 1fr));
    grid-template-rows: max-content;
    justify-content: space-outside;
    justify-items: center;
    box-sizing: border-box;
    gap: 10px;
    padding: 10px;
    overflow-y: scroll;
    max-width: 100%;
    height: calc( 100% - 100px );
`;

const AssetCard = styled.div`
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: row;
    width: 200px;
    height: 200px;
    padding: 10px;
    margin: 5px 10px;
    border-radius: 5px;
    border: 1px solid #ccc;
    font-size: 15px;
    text-align: center;
    &:hover {
        border-color: #f55;
    }
`;

const AssetCardTitle = styled.div`
    position: absolute;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    bottom: 5px;
    width: calc( 100% - 10px );
    text-align: left;
    color: #aaa;
    font-size: 11px;
`;

const AssetCardDeleteBtn = styled(MdDeleteOutline)`
    display: none;
    position: absolute;
    top: 5px;
    right: 5px;
    font-size: 20px;
    cursor: pointer;
    color: #a55;
    transition: color 0.3s;
    &:hover {
        color: #f00;
    }
    ${ AssetCard }:hover & {
        display: block;
    }
`;

//

export const AddAssetsModelsPopup = ( { category, onClose }: { category: string, onClose?: () => void } ) => {

    const closePopupRef = useRef<() => void>(() => {});
    const [ dragOver, setDragOver ] = useState<boolean>( false );
    const [ modelsList, setModelsList ] = useState<{ [key:string]: IMapModelAsset }>( {} );
    const { map, setMap } = useContext( MapContext );
    const { loadModel } = useAssetManager();

    //

    const dragOverHandler = ( event: any ) => {

        event.preventDefault();
        event.stopPropagation();

        setDragOver( true );

    };

    const dragLeaveHandler = ( event: any ) => {

        event.preventDefault();
        event.stopPropagation();

        if ( event.clientX !== 0 && event.clientY !== 0 ) return;

        setDragOver( false );

    };

    const dropHandler = async ( event: any ) : Promise<void> => {

        event.preventDefault();
        setDragOver( false );

        const files = event.dataTransfer?.files;

        if ( files ) {

            const newModels: { [key:string]: IMapModelAsset } = {};

            for ( let i = 0; i < files.length; i ++ ) {

                const id = uuidv4();
                newModels[ id ] = { id, name: files[ i ].name, buffer: await files[ i ].arrayBuffer(), triangles: 0, preview: '', textureId: '' };

            }

            setModelsList( ( prev ) => ({ ...prev, ...newModels }) );

        }

    };

    //

    useEffect( () => {

        window.addEventListener( 'dragover', dragOverHandler );
        window.addEventListener( 'dragleave', dragLeaveHandler );
        document.addEventListener( 'dragend', dragLeaveHandler );

        return () => {

            window.removeEventListener( 'dragover', dragOverHandler );
            window.removeEventListener( 'dragleave', dragLeaveHandler );
            document.removeEventListener( 'dragend', dragLeaveHandler );

        };

    }, [] );

    useEffect( () => {

        if ( ! map || ! map.assets.models[ category ] || ! category ) return;

        const models: { [key:string]: IMapModelAsset } = {};
        const oldModelsList = map?.assets.models[ category ].list ?? {};

        for ( const modelId in oldModelsList ) {

            models[ modelId ] = { ...oldModelsList[ modelId ] };

        }

        setModelsList( models );

    }, [ category ] );

    //

    const SaveBtnClickHandler = () => {

        setMap( ( prev: IMap | null ) => {

            if ( ! prev ) return prev;

            const newMap = { ...prev };
            newMap.assets.models[ category ].list = { ...modelsList };

            for ( const modelId in modelsList ) {

                const model = modelsList[ modelId ];
                loadModel( model.id, model.name, model.buffer );

            }

            return newMap;

        });

        closePopupRef.current();

    };

    const DeleteAsset = ( modelId: string ) => {

        setModelsList( ( prev ) => {

            const newList = { ...prev };
            delete newList[ modelId ];
            return newList;

        } );

    };

    const SelectModelTexture = ( modelId: string, textureId: string ) => {

        setModelsList( ( prev ) => {

            return { ...prev, [ modelId ]: { ...prev[ modelId ], textureId } };

        });

    };

    //

    if ( ! category ) return null;

    return (
        <PopupWrapper name={ 'AddAssetsModel' } closePopupRef={ closePopupRef } onClose={ onClose } >
            <PopupContainer>
                <PopupTitle>"{ map?.assets.models[ category ].title }" category assets</PopupTitle>
                <AssetList>
                    {
                        Object.keys( modelsList ).map( ( id, index ) => (
                            <AssetCard key={ index }>
                                <AssetCardTitle>{ modelsList[ id ].name }</AssetCardTitle>
                                <ModelPreviewImage model={ modelsList[ id ] } width={ 200 } height={ 200 } name={ modelsList[ id ].name } />
                                <AssetCardDeleteBtn onClick={ DeleteAsset.bind( this, id ) } />
                                <TextureSelectDropdown value={ modelsList[ id ].textureId } onSelect={ ( textureId ) => SelectModelTexture( id, textureId ) } />
                            </AssetCard>
                        ))
                    }
                </AssetList>
                <CloseBtn onClick={ () => closePopupRef?.current() } />
                <CreateBtn onClick={ SaveBtnClickHandler }>SAVE</CreateBtn>
                <AssetDropZone $dragOver={ dragOver } $active={ Object.keys( modelsList ).length === 0 || dragOver } onDrop={ dropHandler } >
                    <AssetDropZoneTitle>Drop GLBs here</AssetDropZoneTitle>
                </AssetDropZone>
            </PopupContainer>
        </PopupWrapper>
    );

};
