
import { createContext, useContext, useEffect, useRef, useState } from "react";
import { Object3D, Texture, TextureLoader, WebGLRenderer } from "three";
import { DRACOLoader } from "three/examples/jsm/loaders/DRACOLoader";
import { GLTFLoader } from "three/examples/jsm/loaders/GLTFLoader";
import { KTX2Loader } from "three/examples/jsm/loaders/KTX2Loader";
import { cloneArrayBuffer } from "../utils/Math.util";

//

const textures: { [key:string]: string } = {
    terrainDiffuseAtlas:    'assets/textures/GroundDiffuseAtlas.ktx2',
    terrainNormalAtlas:     'assets/textures/GroundNormalAtlas.ktx2',
    noise:                  'assets/textures/Noise.ktx2'
};

interface AssetManagerContextType {
    textures:       { [key:string]: Texture };
    models:         { [key:string]: Object3D };
    loadTexture:    ( id: string, name: string, buffer: ArrayBuffer ) => void;
    loadModel:      ( id: string, name: string, buffer: ArrayBuffer ) => void;
    ready:          boolean;
};

const AssetManagerContext = createContext<AssetManagerContextType>({
    textures:       {},
    models:         {},
    loadTexture:    () => {},
    loadModel:      () => {},
    ready:          false
});

export const useAssetManager = () => {

    return useContext( AssetManagerContext );

};

export const AssetManagerProvider = ( { children }: any ) => {

    const [ texturesList, setTexturesList ] = useState<{ [key:string]: Texture }>( {} );
    const [ modelsList, setModelsList ] = useState<{ [key:string]: Object3D }>( {} );
    const [ ready, setReady ] = useState( false );
    const [ texturesLoadedCount, setTexturesLoadedCount ] = useState( 0 );
    const ktxLoader = useRef<KTX2Loader>( new KTX2Loader() );
    const gl = useRef( new WebGLRenderer() );

    //

    useEffect( () => {

        ktxLoader.current.setTranscoderPath( 'libs/basis/' ).detectSupport( gl.current );

        for ( let name in textures ) {

            ktxLoader.current.load( textures[ name ], ( texture ) => {

                setTexturesList( ( prev ) => ({ ...prev, [ name ]: texture as Texture }) );
                setTexturesLoadedCount( ( prev ) => {

                    if ( prev + 1 === Object.keys( textures ).length ) setReady( true );

                    return prev + 1;

                });

            });

        }

    }, [] );

    const loadTexture = async ( id: string, name: string, buffer: ArrayBuffer ) => {

        return new Promise( ( resolve ) => {

            const bufferCopy = cloneArrayBuffer( buffer );

            if ( name.indexOf( 'ktx2' ) !== -1 ) {

                const ktx2Loader = new KTX2Loader();
                const renderer = new WebGLRenderer();
                ktx2Loader.setTranscoderPath( 'libs/basis/' ).detectSupport( renderer );

                // @ts-ignore
                ktx2Loader.parse( bufferCopy, ( texture ) => {

                    setTexturesList( ( prev ) => ({ ...prev, [ id ]: texture }) );
                    renderer.forceContextLoss();
                    renderer.dispose();
                    return resolve( texture );

                }, ( error: any ) => {

                    console.error( error );

                });

            } else {

                const blob = new Blob( [ bufferCopy ], { type: 'image/' + name.split('.')[1] } );
                const url = URL.createObjectURL( blob );

                const textureLoader = new TextureLoader();

                textureLoader.load( url, ( texture ) => {

                    texture.flipY = false;
                    setTexturesList( ( prev ) => ({ ...prev, [ id ]: texture }) );
                    return resolve( texture );

                });

            }

        });

    };

    const loadModel = async ( id: string, name: string, buffer: ArrayBuffer ) => {

        return new Promise( ( resolve ) => {

            const dracoLoader = new DRACOLoader();
            dracoLoader.setDecoderPath('/libs/draco/');

            const loader = new GLTFLoader();
            loader.setDRACOLoader( dracoLoader );

            loader.parse( buffer, '', ( gltf ) => {

                setModelsList( ( prev ) => ({ ...prev, [ id ]: gltf.scene }) );
                return resolve( gltf.scene );

            });

        });

    };

    //

    return (
        <AssetManagerContext.Provider value={{ textures: texturesList, models: modelsList, loadTexture, loadModel, ready }} >
            { children }
        </AssetManagerContext.Provider>
    );

};
